import { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useCentrinnoContext } from '../../context/storyContext'
import { runForceGraph } from '../../helpers/runForceGraph'
import { slugify } from '../../helpers/slugify'
import {
  Button,
  backgroundColor,
  fadedWhite,
  secondColor,
  textColor,
} from '../../styles'
import { IContext, IStory, IPreference, DData } from '../../types/types'
import { GraphContainer } from './styles'
import { AnimatePresence, motion } from 'framer-motion'
import {
  hidden,
  hiddenDown,
  hide,
  show,
  showAndRaise,
  showDelay,
} from '../../helpers/animationVariants'
import { Info } from '../StoryCard/styles'
import { isMobile } from '../../helpers/windowHelpers'
import { ButtonContainer } from '../ForceGraphStory/styles'
import { ButtonRow } from '../StoryContent/Metadata/styles'

interface ForceGraphProps {
  relatedStories: IStory[]
  selectedVariable: IPreference
  selectedVariables: IPreference[]
}

export const ForceGraph = ({
  relatedStories,
  selectedVariable,
  selectedVariables,
}: ForceGraphProps) => {
  const containerRef = useRef(null)
  const { stories, about } = useCentrinnoContext() as IContext
  const [displayCta, setDisplayCta] = useState(false)
  const [displayNodeDescription, setDisplayNodeDescription] = useState(false)
  const [nodeData, setNodeData] = useState<DData>({})
  const [normal, setNormal] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [showGraph, setShowGraph] = useState(false)
  const [isRemoved, setIsRemoved] = useState(false)
  const isStory = nodeData && nodeData.class === 'story-node'
  const location = useLocation()

  useEffect(() => {
    setDisplayCta(false)
    setDisplayNodeDescription(false)
    let destroyFn

    if (containerRef.current) {
      const { destroy } = runForceGraph(
        containerRef.current,
        relatedStories,
        setDisplayCta,
        setNodeData,
        setDisplayNodeDescription,
        selectedVariables,
        stories
      )
      destroyFn = destroy
    }

    return destroyFn
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariables.length, showGraph])

  useEffect(() => {
    if (
      location.pathname.includes('story') ||
      location.pathname.includes('about') ||
      location.pathname.includes('index')
    ) {
      onMinimize()
    }
  }, [location])

  const handlenormalSize = () => {
    setNormal(true)
    setTimeout(() => {
      setShowGraph(true)
    }, 500)
  }

  const onMinimize = () => {
    setShowGraph(false)
    setIsRemoved(false)
    setTimeout(() => {
      setNormal(false)
      setExpanded(false)
    }, 400)
  }

  const onExpand = () => {
    setShowGraph(false)
    setExpanded(!expanded)
    setTimeout(() => {
      setShowGraph(true)
    }, 600)
  }

  const minimize = {
    opacity: 1,
    width: isMobile ? 80 : 100,
    height: isMobile ? 80 : 100,
    top: isMobile ? 16 : 32,
    right: isMobile ? 16 : 32,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  }

  const normalSize = {
    opacity: 1,
    width: isMobile ? 'calc(100% - 8px)' : 600,
    height: isMobile ? '85vh' : 600,
    top: isMobile ? 4 : 32,
    right: isMobile ? 4 : 32,
    transition: {
      duration: 0.4,
      ease: 'easeInOut',
    },
  }

  const expand = {
    opacity: 1,
    // width: isMobile ? 'calc(100% - 8px)' : 'calc(100% - 64px)',
    width: '100vw',
    height: '100vh',
    // height: isMobile ? '85vh' : 'calc(100% - 64px)',
    // top: isMobile ? 4 : 32,
    // right: isMobile ? 4 : 32,
    top: 0,
    right: 0,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 1,
      duration: 0.3,
      delay: 0.2,
      ease: 'easeInOut',
    },
  }

  const renderLabel = () => {
    switch (nodeData.class) {
      case 'cat-node':
        return <p className="cat">category</p>
      case 'tag-node':
        return <p className="tag">tag</p>
      case 'story-node':
        return <p className="story">story</p>
    }
  }

  const removePopup = () => {
    setIsRemoved(true)
  }

  const minimalSize = !expanded && !normal

  return (
    <GraphContainer
      initial={hidden}
      animate={expanded ? expand : normal ? normalSize : minimize}
      exit={hide}
      className={`${!normal ? 'minimized' : expanded ? 'expanded' : 'normal'}`}
    >
      <AnimatePresence>
        {!normal && (
          <motion.div initial={hidden} animate={show} exit={hide}>
            <Button
              className="graph-open"
              position="absolute"
              onClick={() => handlenormalSize()}
              buttonColor={secondColor}
              backgroundColor={'rgba(255, 255, 255, 0.3)'}
            >
              <motion.svg
                width="49"
                height="49"
                viewBox="0 0 49 49"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.7755 22.6209L43.7459 5.65039M26.7755 22.6209L15.8793 11.7247M26.7755 22.6209L4.60742 31.8889M26.7755 22.6209V41.9709M26.7755 22.6209L38.6736 30.7617"
                  stroke="#FFC434"
                />
                <circle cx="26.1499" cy="22.8496" r="7.26411" fill="#FFC434" />
                <circle cx="14.71" cy="10.6182" r="2.52541" fill="#FFC434" />
                <circle cx="44.2474" cy="4.54383" r="4.2162" fill="#FFC434" />
                <circle cx="39.8445" cy="32.0559" r="2.90114" fill="#FFC434" />
                <circle cx="26.9008" cy="44.4965" r="4.2162" fill="#FFC434" />
                <circle cx="4.60772" cy="32.056" r="3.67413" fill="#FFC434" />
              </motion.svg>
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showGraph && (
          <>
            <div
              className={`${
                isRemoved ? 'popup-invisible' : 'popup-visible'
              } explainer-popup`}
              onClick={removePopup}
            >
              <div>
                <h3>{about?.graphExplainerTitle}</h3>
                <p>{about?.graphExplainerText}</p>

                <Button
                  backgroundColor={secondColor}
                  buttonColor={secondColor}
                  color={textColor}
                  onClick={removePopup}
                >
                  <p>click to continue</p>
                </Button>
              </div>
            </div>
            <motion.div
              initial={hidden}
              animate={showDelay}
              exit={hide}
              ref={containerRef}
              className="force-graph"
              key="graph"
            ></motion.div>
          </>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {displayNodeDescription && (
          <Info
            initial={hiddenDown}
            animate={showAndRaise}
            exit={hide}
            className="graph-info"
            key="info"
          >
            <div className="info data-info">
              <div className="info-header">
                {nodeData.heroImage && (
                  <div className="container-img">
                    <img src={nodeData.heroImage} alt="" />
                  </div>
                )}

                {displayCta && (
                  <Link
                    to={
                      isStory
                        ? `/story/${slugify(nodeData.name as string)}`
                        : ''
                    }
                  >
                    <Button
                      backgroundColor={fadedWhite}
                      buttonColor={secondColor}
                      className="arrow"
                    >
                      <svg
                        width="23"
                        height="15"
                        viewBox="0 0 23 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <motion.path
                          d="M21.503 1.9772L11.492 11.9882L1.48095 1.9772"
                          stroke={secondColor}
                          strokeWidth="2"
                        />
                      </svg>
                    </Button>
                  </Link>
                )}
              </div>

              <h3>{nodeData.name}</h3>
              <p>
                {nodeData.definition
                  ? nodeData.definition
                  : nodeData.summary
                  ? nodeData.summary
                  : ''}
              </p>

              <ButtonRow>
                {nodeData.tags &&
                  nodeData.tags.map((tag: IPreference, index: number) => (
                    <Button
                      key={index}
                      buttonTextColor={textColor}
                      buttonColor={secondColor}
                      backgroundColor={secondColor}
                      className="small fake"
                    >
                      <p>{tag.title}</p>
                    </Button>
                  ))}
              </ButtonRow>
              <div className="labels">{renderLabel()}</div>
            </div>
          </Info>
        )}
      </AnimatePresence>

      {normal && (
        <>
          {!isMobile && (
            <motion.div
              initial={hidden}
              animate={showDelay}
              exit={hide}
              className={!expanded ? 'expand' : 'expand minimize'}
            >
              <Button
                className="close"
                position="absolute"
                onClick={() => onExpand()}
                backgroundColor={backgroundColor}
                buttonColor={secondColor}
              ></Button>
            </motion.div>
          )}
          <motion.div initial={hidden} animate={showDelay} exit={hide}>
            <Button
              className="close"
              position="absolute"
              onClick={() => onMinimize()}
              backgroundColor={backgroundColor}
              buttonColor={secondColor}
            >
              <svg
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.983398 14.6211L14.1768 1.42771"
                  stroke={textColor}
                  strokeWidth="2"
                />
                <path
                  d="M0.983398 1.42725L14.1768 14.6206"
                  stroke={textColor}
                  strokeWidth="2"
                />
              </svg>
            </Button>
          </motion.div>
        </>
      )}
      <AnimatePresence>
        {normal && (
          <ButtonContainer
            initial={hidden}
            animate={showDelay}
            exit={hide}
            key="buttons"
          >
            <Button
              className="zoom-button medium"
              backgroundColor={backgroundColor}
              buttonColor={secondColor}
              id="zoom-in"
            >
              <svg
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.983398 14.6211L14.1768 1.42771"
                  stroke={textColor}
                  strokeWidth="2"
                />
                <path
                  d="M0.983398 1.42725L14.1768 14.6206"
                  stroke={textColor}
                  strokeWidth="2"
                />
              </svg>
            </Button>
            <Button
              className="zoom-button medium"
              backgroundColor={backgroundColor}
              buttonColor={secondColor}
              id="zoom-out"
            >
              <div className="bar"></div>
            </Button>
            <Button
              className="zoom-button medium"
              backgroundColor={backgroundColor}
              buttonTextColor={textColor}
              buttonColor={secondColor}
              id="zoom-init"
            >
              <p>reset</p>
            </Button>
          </ButtonContainer>
        )}
      </AnimatePresence>
    </GraphContainer>
  )
}
