import { DividerLine } from './styles'

function Divider() {
  return (
    <DividerLine>
      <div className="inner"></div>
    </DividerLine>
  )
}

export default Divider
