import styled from 'styled-components'
import { backgroundColor } from '../../styles'

export const LayoutWrapper = styled.main`
  position: relative;
  height: 100%;
  background-color: ${backgroundColor};

  &.loading {
    cursor: wait;
  }
  &.blocked {
    overflow: hidden;
  }
`

export const MainContent = styled.section`
  position: relative;
  z-index: 2;
  background-color: ${backgroundColor};
`
