import React from 'react'

interface CloseIconProps {
  handleClear: () => void
}

const CloseIcon = ({ handleClear }: CloseIconProps) => {
  return (
    <div style={{ marginBottom: 2 }}>
      <svg
        width="20"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={handleClear}
      >
        <path
          d="M0.983398 14.6211L14.1768 1.42771"
          stroke="#1E1E1E"
          strokeWidth="2"
        />
        <path
          d="M0.983398 1.42725L14.1768 14.6206"
          stroke="#1E1E1E"
          strokeWidth="2"
        />
      </svg>
    </div>
  )
}

export default CloseIcon
