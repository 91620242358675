import styled from 'styled-components'
import { motion } from 'framer-motion'
import {
  Button,
  accentColor,
  backgroundColor,
  secondColor,
  textColor,
  thirdColor,
} from '../../../styles'

export const MetaDataSection = styled.section`
  position: relative;
  width: 100%;
  padding: 56px 32px 32px;
  display: flex;

  .left-half,
  .right-half {
    width: 50%;
    border-top: 1px solid ${textColor};
  }

  .left-half {
    position: relative;
    padding-top: 24px;

    .share {
      position: absolute;
      bottom: 0;
      left: 0;

      display: flex;
      justify-content: flex-start;
      align-items: center;

      .copy-button {
        padding: 0;
        width: 40px;
        height: 40px;
        border-radius: 12px;
        background-color: ${accentColor};
        display: flex;
        justify-content: center;
        align-items: center;

        .square {
          position: absolute;
          border: 1px solid ${backgroundColor};
          background-color: ${accentColor};
          width: 12px;
          height: 15px;
          transform: translate(-2px, -2px);
          z-index: 2;
          transition: all 0.2s ease;

          &:last-child {
            transform: translate(2px, 2px);
            z-index: 1;
          }
        }

        &:hover {
          background-color: ${thirdColor};
          border-color: ${thirdColor};

          .square {
            background-color: ${thirdColor};
          }
        }
      }

      p {
        margin-bottom: 0;
        margin-left: 0.6em;
      }
    }

    h2 {
      margin-bottom: 16px;
    }
  }

  @media screen and (max-width: 1150px) {
    padding: 40px 24px 24px;

    .left-half {
      padding-top: 16px;

      h2 {
        font-size: 18px;
      }
    }
  }

  @media screen and (max-width: 1050px) {
    flex-wrap: wrap;

    .left-half,
    .right-half {
      width: 100%;
    }

    .left-half {
      padding-bottom: 80px;

      .copy-button {
        bottom: 16px;
      }

      h2 {
        margin-bottom: 8px;
      }
    }
  }

  @media screen and (max-width: 800px) {
    padding: 24px 16px 24px;

    h4 {
      font-size: 14px !important;
    }
  }
`

export const ButtonRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-right: 24px;
  gap: 6px;
  ${Button} {
    flex: none;
  }
`

export const Row = styled.div`
  padding: 24px 0;
  border-bottom: 1px solid ${textColor};

  h4 {
    font-size: 16px;
    font-weight: 300;

    span {
      font-weight: 600;
      margin-right: 6px;
    }
  }

  @media screen and (max-width: 1150px) {
    padding: 16px 0;
  }
`
