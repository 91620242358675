import Intro from '../Intro'
import { LayoutWrapper, MainContent } from './styles'
import { useCentrinnoContext } from '../../context/storyContext'
import { setTagButtons } from '../../helpers/tagButtons'
import { useEffect, useState } from 'react'
import { searchButtons } from '../../helpers/searchItem'
import { IContext, IPreference, IResult, IStory } from '../../types/types'
import Results from '../Results'
import { Search } from '../Search'
import Slider from '../Slider'
import { ForceGraph } from '../ForceGraph'
import { shuffleResults } from '../../helpers/shuffle'
import { AnimatePresence } from 'framer-motion'
import Footer from '../Footer/Footer'

const HomeContent = () => {
  const [filteredButtons, setFilteredButtons] = useState<IResult[]>([])
  const [inputValue, setInputValue] = useState('')
  const [refinedButtons, setRefinedButtons] = useState<IResult[]>([])
  const [filteredStories, setFilteredStories] = useState<IStory[]>([])
  const [selectedVariable, setSelectedVariable] = useState<IPreference>(
    {} as IPreference
  )

  const [selectedVariables, setSelectedVariables] = useState<IPreference[]>([])
  const [relatedStories, setRelatedStories] = useState<IStory[]>([])
  const [renderFlag, setRenderFlag] = useState<boolean>(false)

  const { tags, loader } = useCentrinnoContext() as IContext

  useEffect(() => {
    if (loader) {
      document.body.classList.add('blocked')
    } else {
      document.body.classList.remove('blocked')
    }
  }, [])

  useEffect(() => {
    setFilteredButtons(shuffleResults(setTagButtons(tags)))
  }, [tags])

  useEffect(() => {
    setRefinedButtons(filteredButtons)
  }, [renderFlag])

  useEffect(() => {
    setRefinedButtons(searchButtons(inputValue, filteredButtons))
  }, [inputValue, filteredButtons, renderFlag])

  return (
    <>
      <LayoutWrapper className={loader ? 'loading blocked' : ''}>
        {selectedVariables.length > 0 && (
          <ForceGraph
            selectedVariable={selectedVariable}
            selectedVariables={selectedVariables}
            relatedStories={relatedStories}
          />
        )}
        <MainContent>
          <Intro title="The Living Archive" />

          <Search
            inputValue={inputValue}
            setInputValue={setInputValue}
            setButtons={setRefinedButtons}
            buttons={filteredButtons}
            setRenderFlag={setRenderFlag}
            renderFlag={renderFlag}
          />
          <AnimatePresence>
            {/* {refinedButtons.length > 0 && ( */}
            <Results
              setSelectedVariables={setSelectedVariables}
              setRelatedStories={setRelatedStories}
              setButtons={setRefinedButtons}
              buttons={refinedButtons}
            />
            {/* )} */}
          </AnimatePresence>
          <Slider
            relatedStories={relatedStories}
            selectedVariables={selectedVariables}
          />
        </MainContent>
        <Footer />
      </LayoutWrapper>
    </>
  )
}

export default HomeContent
