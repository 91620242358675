import imageUrlBuilder from "@sanity/image-url"
import { IStory, IPreference, IImage, INode, ILink } from "../types/types"
import client from "../sanityService"

export const jsonFy = (
  relatedStories: IStory[],
  selectedVariables: IPreference[] | undefined,
  stories: IStory[]
) => {
  const builder = imageUrlBuilder(client)

  function urlFor(source: IImage) {
    return builder.image(source)
  }

  const nodesPreferences = selectedVariables?.map((variable: IPreference) => {
    return {
      id: variable.title,
      class: !!variable?.relatedTags ? "cat-node" : "tag-node",
      definition: variable.definition,
    }
  })

  let connectedNodes: INode[] = []
  let firstLevelLinks: ILink[] = []

  selectedVariables?.forEach((variable: IPreference) => {
    if (variable.relatedTags) {
      variable.relatedTags.forEach((tag: IPreference) => {
        connectedNodes.push({
          id: tag.title,
          class: "tag-node",
        })
        firstLevelLinks.push({
          source: variable?.title,
          target: tag.title,
        })
      })
    } else {
      connectedNodes.push({
        id: variable.category?.title,
        class: "cat-node",
      })
      firstLevelLinks.push({
        source: variable?.title,
        target: variable?.category?.title,
      })
    }
  })

  let connectedStoryNodes: INode[] = []
  let storiesLinks: ILink[] = []

  selectedVariables?.forEach((variable: IPreference) => {
    if (!variable.relatedTags) {
      stories.forEach((story: IStory) => {
        story.tags.forEach((tag: IPreference) => {
          if (tag.title === variable.title) {
            connectedStoryNodes.push({
              id: story.title,
              class: "story-node",
              tags: story.tags,
              image:
                story.heroImage && urlFor(story.heroImage).width(200).format('webp').url(),
              summary: story.summaryText,
            })
            storiesLinks.push({
              source: tag.title,
              target: story.title,
            })
          }
        })
      })
    } else {
      stories.forEach((story: IStory) => {
        story.tags.forEach((tag: IPreference) => {
          if (tag.category?.title === variable.title) {
            connectedStoryNodes.push({
              id: story.title,
              class: "story-node",
              tags: story.tags,
              image:
                story.heroImage && urlFor(story.heroImage).width(200).format('webp').url(),
              summary: story.summaryText,
            })
            storiesLinks.push({
              source: tag.title,
              target: story.title,
            })
          }
        })
      })
    }
  })
  connectedStoryNodes = connectedStoryNodes.filter(
    (value: INode, index: number, self: INode[]) =>
      index === self.findIndex((t: INode) => t.id === value.id)
  )

  const newData = {
    nodes: [
      ...(nodesPreferences as INode[]),
      ...(connectedNodes as INode[]),
      ...connectedStoryNodes,
    ],
    links: [...firstLevelLinks, ...storiesLinks],
  }
  return newData
}
