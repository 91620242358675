import { cubicBezier, motion } from 'framer-motion'
import { IntroSection } from './styles'
import { hidden, hide, showDelay } from '../../helpers/animationVariants'

interface IntroProps {
  title: string
  className?: string
}

const Intro = ({ title, className }: IntroProps) => {
  const words = title.split(' ')

  const line1 = words[0]
  const line2 = words.length > 1 && words[1]
  const line3 = words.length > 2 && words[2]

  const letter = words && words.map((word) => word.split(''))

  const sentence = {
    initial: {
      opacity: 1,
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 1,
        delayChildren: 0.4,
        staggerChildren: 0.05,
      },
    },
  }

  const letterAni = {
    initial: {
      y: 400,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1,
        ease: cubicBezier(0.6, 0.01, -0.05, 0.95),
      },
    },
  }

  return (
    <IntroSection className={className}>
      <motion.h1 variants={sentence} initial="initial" animate="animate">
        <div>
          {line1.split('').map((char, index) => {
            return (
              <motion.span key={char + '-' + index} variants={letterAni}>
                {char}
              </motion.span>
            )
          })}
        </div>
        <div>
          {line2 &&
            line2.split('').map((char, index) => {
              return (
                <motion.span key={char + '-' + index} variants={letterAni}>
                  {char}
                </motion.span>
              )
            })}
        </div>
        <div>
          {line3 &&
            line3.split('').map((char, index) => {
              return (
                <motion.span key={char + '-' + index} variants={letterAni}>
                  {char}
                </motion.span>
              )
            })}
        </div>
      </motion.h1>

      <motion.div
        initial={hidden}
        animate={{ opacity: 1 }}
        transition={{
          duration: 1,
          delay: title === 'about' ? 1 : 2.2,
          ease: cubicBezier(0.6, 0.01, -0.05, 0.95),
        }}
        exit={hide}
        className="intro-image"
      >
        {className === 'about' ? (
          <h2>
            Connecting local stories from (post)-industrial sites to inspire a
            circular and inclusive future in Europe
          </h2>
        ) : (
          <svg
            width="303"
            height="126"
            viewBox="0 0 279 117"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M279 66.0254H272.43V7.25449H171.444V66.0254L88.6928 12.199V65.99L6.57041 12.1636V66.0254H0V0.581787L82.1224 54.4082V0.66181L164.874 54.4882V0.66181H279V66.0254Z"
              fill="#E5E7E8"
            />
            <path
              d="M25.5562 105.811C24.855 107.548 23.6495 109.034 22.0961 110.075C20.5164 111.114 18.4996 111.66 16.0748 111.66C13.7037 111.66 11.6608 111.142 9.99824 110.103C8.36135 109.087 7.05505 107.614 6.23913 105.865C5.36645 104.061 4.93087 102.018 4.93087 99.7954C4.93087 97.5547 5.36645 95.5034 6.23913 93.7275C7.07149 91.9891 8.37407 90.5206 9.99824 89.4893C11.6608 88.4505 13.7052 87.9304 16.0748 87.9304C18.4996 87.9304 20.5226 88.4505 22.0961 89.517C23.6567 90.5502 24.8643 92.0385 25.5562 93.7813L25.7479 94.2184H30.9518L30.6236 93.2627C29.9614 91.2812 28.8917 89.4615 27.4837 87.9215C26.0757 86.3815 24.3609 85.1556 22.4504 84.3232C20.4888 83.4476 18.2266 83.0105 15.8018 83.0105C12.5595 83.0105 9.72678 83.78 7.35567 85.2788C5.0006 86.7603 3.10436 88.8719 1.88033 91.376C0.625753 93.8906 0 96.7345 0 99.7954C0 102.856 0.625753 105.702 1.88033 108.216C3.10436 110.72 5.0006 112.832 7.35567 114.313C9.72678 115.817 12.5595 116.582 15.8018 116.582C18.2557 116.582 20.4888 116.145 22.4504 115.269C24.3566 114.437 26.0683 113.215 27.4759 111.68C28.8834 110.145 29.9555 108.332 30.6236 106.357L30.9518 105.4H25.7479L25.5562 105.811Z"
              fill="#E5E7E8"
            />
            <path
              d="M35.9368 116.172H60.3489V111.332H40.9229V102.202H58.2784V97.3082H40.9229V88.2317H60.3489V83.3933H35.9368V116.172Z"
              fill="#E5E7E8"
            />
            <path
              d="M89.4778 108.297L71.2496 83.3933H66.0734V116.172H71.0318V91.1571L89.2584 116.172H94.4362V83.3933H89.4778V108.297Z"
              fill="#E5E7E8"
            />
            <path
              d="M98.9038 88.1763H109.83V116.172H114.788V88.1763H125.687V83.3933H98.9038V88.1763Z"
              fill="#E5E7E8"
            />
            <path
              d="M152.633 102.093C153.932 101.4 155.023 100.371 155.792 99.1117C156.637 97.8006 157.073 96.0508 157.073 93.8902C157.073 90.6923 156.038 88.1224 153.995 86.208C151.952 84.3228 149.227 83.3656 145.848 83.3656H130.181V116.144H135.141V104.115H144.894L152.441 116.144H158.354L149.963 103.218C150.887 102.929 151.781 102.553 152.633 102.093ZM135.141 88.1762H145.417C147.46 88.1762 149.122 88.6964 150.348 89.7351C151.547 90.7462 152.119 92.1143 152.119 93.8902C152.119 95.3121 151.765 96.4063 151.084 97.1711C150.39 97.9637 149.486 98.5422 148.477 98.8393C147.425 99.1602 146.333 99.3256 145.234 99.3302H135.113V88.1762H135.141Z"
              fill="#E5E7E8"
            />
            <path
              d="M168.137 83.3933H163.179V116.172H168.137V83.3933Z"
              fill="#E5E7E8"
            />
            <path
              d="M199.195 108.297L180.94 83.3933H175.763V116.172H180.722V91.1571L198.978 116.172H204.154V83.3933H199.195V108.297Z"
              fill="#E5E7E8"
            />
            <path
              d="M235.188 108.297L216.962 83.3933H211.784V116.172H216.742V91.1571L234.998 116.172H240.174V83.3933H235.188V108.297Z"
              fill="#E5E7E8"
            />
            <path
              d="M277.801 93.2627C277.018 91.282 275.874 89.4649 274.427 87.9042C272.94 86.345 271.15 85.1075 269.168 84.2678C264.835 82.5544 260.014 82.5544 255.681 84.2678C253.687 85.0841 251.893 86.3248 250.423 87.9042C248.964 89.4552 247.819 91.2748 247.049 93.2627C246.251 95.3373 245.853 97.5455 245.877 99.7692C245.869 101.983 246.266 104.179 247.049 106.248C247.819 108.236 248.964 110.056 250.423 111.607C251.911 113.165 253.7 114.402 255.681 115.243C260.024 116.956 264.853 116.956 269.196 115.243C271.19 114.426 272.983 113.186 274.453 111.607C275.91 110.054 277.055 108.235 277.827 106.248C278.619 104.181 279.017 101.983 278.999 99.7692C278.988 97.5463 278.582 95.3431 277.801 93.2627ZM274.013 99.7692C274.013 101.984 273.553 104.006 272.596 105.811C271.69 107.575 270.313 109.051 268.619 110.075C266.903 111.114 264.805 111.66 262.406 111.66C260.036 111.66 257.938 111.142 256.222 110.075C254.514 109.06 253.125 107.582 252.216 105.811C251.267 103.941 250.781 101.868 250.8 99.7692C250.8 97.527 251.261 95.4772 252.216 93.6998C253.126 91.9358 254.515 90.4663 256.222 89.4616C257.966 88.4229 260.036 87.8765 262.406 87.8765C264.805 87.8765 266.903 88.3967 268.619 89.4616C270.306 90.4829 271.681 91.9488 272.596 93.6998C273.549 95.5786 274.036 97.661 274.013 99.7692Z"
              fill="#E5E7E8"
            />
          </svg>
        )}

        <motion.div
          initial={{ height: 0 }}
          animate={{
            height: '100%',
            transition: {
              duration: 1,
              delay: title === 'about' ? 1 : 2,
              ease: 'easeInOut',
            },
          }}
          className="right-border"
        ></motion.div>
      </motion.div>
    </IntroSection>
  )
}

export default Intro
