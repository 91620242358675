import React from 'react'
import { IndexItem } from './styles'
import { IImage, IPreference } from '../../types/types'
import ImgHandler from '../ImgHandler'
import { Button, fadedWhite, secondColor, textColor } from '../../styles'
import { ButtonRow } from '../StoryContent/Metadata/styles'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { slugify } from '../../helpers/slugify'
import { hidden, hide, show } from '../../helpers/animationVariants'

interface IIndexCardProps {
  image: IImage
  title: string
  summary: string
  tags?: IPreference[]
}

const IndexCard = ({ image, title, summary, tags }: IIndexCardProps) => {
  return (
    <IndexItem initial={hidden} animate={show} exit={hide}>
      <Link to={`/story/${slugify(title)}`}>
        <div className="image-square">
          {image && (
            <ImgHandler imgSrc={image} altText={image?.title} width={600} />
          )}
        </div>
      </Link>
      <div className="content">
        <h4>{title}</h4>
        <p className="summary">{summary}</p>
        <h5>This story is about: </h5>
        <ButtonRow>
          {tags?.slice(0, 7)?.map((item: IPreference, index: number) => (
            <Button
              key={index}
              buttonTextColor={textColor}
              buttonColor={secondColor}
              backgroundColor={secondColor}
              className="x-small fake flex"
            >
              <p>{item.title}</p>
            </Button>
          ))}
        </ButtonRow>
        <div className="arrow-container">
          <Link to={`/story/${slugify(title)}`}>
            <Button
              backgroundColor={fadedWhite}
              buttonColor={secondColor}
              className="arrow"
            >
              <svg
                width="23"
                height="15"
                viewBox="0 0 23 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <motion.path
                  d="M21.503 1.9772L11.492 11.9882L1.48095 1.9772"
                  stroke={secondColor}
                  strokeWidth="2"
                />
              </svg>
            </Button>
          </Link>
        </div>
      </div>
    </IndexItem>
  )
}

export default IndexCard
