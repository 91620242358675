import styled from 'styled-components'
import {
  Button,
  accentColor,
  backgroundColor,
  fadedWhite,
  secondColor,
  textColor,
  thirdColor,
} from '../../styles'
import { motion } from 'framer-motion'
import { Info } from '../StoryCard/styles'
import img from '../../images/grid-item.png'
import { ButtonContainer } from '../ForceGraphStory/styles'
import { ButtonRow } from '../StoryContent/Metadata/styles'

export const GraphContainer = styled(motion.div)`
  @keyframes graphGlow {
    0% {
      box-shadow: 0px 0px 4px 0px ${secondColor};
    }

    100% {
      box-shadow: 0px 0px 8px 8px ${secondColor};
    }
  }

  @keyframes graphShow {
    0% {
      opacity: 0 !important;
    }

    100% {
      opacity: 1 !important;
    }
  }

  position: fixed;
  top: 32px;
  right: 32px;
  z-index: 100;
  width: 600px;
  height: 600px;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 2px 2px 4px rgba(30, 30, 30, 0.2);
  overflow: hidden;
  animation: graphShow 0.5s 1s ease forwards;

  &.normal,
  &.expanded {
    border: 1px solid rgba(0, 0, 0, 0.3);
    z-index: 121;
  }

  .explainer-popup {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2.5rem;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    transition: all 0.3s ease;
    z-index: 122;

    &.popup-invisible {
      opacity: 0;
      pointer-events: none;
    }

    h3 {
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 2rem;
    }

    button p {
      margin: 0;
    }
  }

  &.minimized {
    animation: graphGlow 1s ease alternate infinite;
  }

  ${ButtonContainer} {
    flex-direction: row-reverse;
    bottom: unset;
    right: unset;
    top: 12px;
    left: 12px;

    ${Button} {
      margin-right: 6px;
    }
  }

  &.expanded {
    ${Info} {
      max-width: 40%;
      height: calc(100% - 24px);

      .data-info {
        .info-header {
          height: auto;
          margin-bottom: 1.5rem;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          gap: 12px;
          .container-img {
            display: block;
            width: 30%;
            height: auto;
            aspect-ratio: 1;
            border-radius: 5px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              aspect-ratio: 1;
              object-fit: cover;
            }
          }

          ${Button} {
            width: 80px;
            height: 80px;
            position: relative;
            right: 0;
            top: 0;
            transform: translateX(0);
          }
        }

        ${ButtonRow} {
          display: flex;
          flex-wrap: wrap;
          margin-top: 1rem;
          gap: 6px;

          ${Button} {
            aspect-ratio: unset;
            
            width: auto;
            padding: 4px 8px;
            p {
              width: 100%;
            }
          }
        }

        .labels {
          top: 12px;
          pointer-events: none;
        }
      }
    }

    ${ButtonContainer} {
      top: unset;
      left: unset;
      bottom: 12px;
      right: 12px;
    }
  }

  .force-graph {
    width: 100%;
    height: 100%;
    background-image: url(${img});
    background-size: 10px;

    svg {
      overflow: visible;
      cursor: move;
    }

    text {
      font-size: 10px;
    }
    p {
      font-size: 8px;
      margin-bottom: 0;
      text-align: center;
      padding: 4px;
      background-color: ${backgroundColor};
      border-radius: 4px;
      font-size: 8px;
      margin-bottom: 0;
      text-align: center;
      user-select: none;
      cursor: grab;

      &.cat-node {
        border: 1px solid ${accentColor};
      }
      &.tag-node {
        border: 1px solid ${secondColor};
      }
      &.story-node {
        border: 1px solid ${thirdColor};
      }
      &.main-story-node {
        font-size: 10px;
        background-color: ${thirdColor};
      }
    }
    .node-image {
      width: 100%;
      height: 100%;
      border-radius: 3px;
      user-select: none;
      cursor: grab;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;
      }
    }
    circle {
      cursor: grab;
    }
  }
  .graph-info {
    max-width: 70%;

    .info-header {
      .container-img {
        display: none;
      }

      margin: 0;
      height: 0;

      ${Button} {
        position: absolute;
        right: -8px;
        top: 0;
        transform: translateX(100%);
      }
    }

    ${ButtonRow} {
      display: none;
    }

    .labels {
      left: unset;
      right: 4px;
      justify-content: flex-end;
    }
    &.legenda {
      .info {
        background-color: ${fadedWhite};
        backdrop-filter: blur(5px);

        ul {
          margin: 0.5em 0.25em;
          padding-left: 1em;

          li {
            position: relative;
            list-style: none;
            font-size: 11px;

            &::before {
              content: '';
              position: absolute;
              top: 50%;
              transform: translate(0, -50%);
              left: -1.2em;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: ${secondColor};
            }

            &:first-child {
              &::before {
                background-color: ${accentColor};
              }
            }

            &:last-child {
              &::before {
                background-color: ${thirdColor};
              }
            }
          }
        }
      }
    }
  }

  .expand {
    position: absolute;
    top: 0;
    right: 38px;

    .graph-info {
      .info-header {
        .container-img {
          display: none;
        }

        margin: 0;
        height: 0;

        ${Button} {
          position: absolute;
          right: -8px;
          top: 0;
          transform: translateX(100%);
        }
      }
    }

    ${Button} {
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60%;
        height: 60%;
        transform: translate(-50%, -50%);
        background-color: transparent;
        border: 2px solid ${textColor};
        transition: all 0.2s ease;
      }
    }
    &.minimize {
      ${Button} {
        &::before {
          border: none;
          background-color: ${textColor};
          height: 2px;
        }
      }
    }
  }

  .bar {
    width: 14px;
    height: 2px;
    background-color: ${textColor};
  }

  .graph-open {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
  }

  @media screen and (max-width: 1150px) {
    top: 24px;
    right: 24px;
  }

  @media screen and (max-width: 800px) {
    ${Info} {
      p {
        display: -webkit-box;
        width: 90%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        &.story,
        &.tag,
        &.cat {
          width: auto;
        }
      }
    }
    .graph-open {
      width: 64px;
      height: 64px;
      padding: 16px;
    }

    .force-graph {
      max-width: none;
      background-size: 10px;
      p {
        font-size: 7px;
      }
    }
  }
`
