import styled from 'styled-components'
import {
  backgroundColor,
  secondBgColor,
  secondColor,
  textColor,
} from '../../styles'

export const RangeContainer = styled.div`
  position: relative;
  width: 100%;

  &.volume {
    margin-left: 16px;
    width: 20%;
  }

  &.in-video {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;

    p {
      position: relative;
      top: unset;
      left: unset;
      bottom: unset;
    }
  }

  p {
    position: absolute;
    font-size: 10px;
    bottom: -10px;
    left: 2px;
    margin-bottom: 0;
  }

  @media only screen and (max-width: 800px) {
    &.volume {
      margin-left: 4px;
      width: 45%;
    }
    p {
      font-size: 8px;
      left: 4px;
      bottom: -5px;
    }
  }
`

export const RangeSlider = styled.input`
  width: 100%;
  height: 8px;
  appearance: none;
  -webkit-appearance: none;
  background-color: ${backgroundColor};
  border: 1px solid ${textColor};
  border-radius: 20px;
  outline: none;
  opacity: 1;
  transition: opacity 0.3s ease;
  overflow: hidden;
  cursor: pointer;

  &::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    background-color: ${textColor};
    border-radius: 20px;
    box-shadow: -2006px 0 0 2000px ${textColor};
  }
`
