import { ChangeEvent, useState } from 'react'
import { InputBox, SearchBox } from './styles'
import { AnimatePresence, motion } from 'framer-motion'
import MagnifierIcon from './MagnifierIcon'
import CloseIcon from './CloseIcon'
import { hidden, hide, show } from '../../helpers/animationVariants'
import { Button, backgroundColor, textColor, thirdColor } from '../../styles'
import { IContext, IResult } from '../../types/types'
import { shuffleResults } from '../../helpers/shuffle'
import { setTagButtons } from '../../helpers/tagButtons'
import { useCentrinnoContext } from '../../context/storyContext'

interface SearchProps {
  inputValue: string
  setInputValue: React.Dispatch<React.SetStateAction<string>>
  buttons: IResult[]
  setButtons: React.Dispatch<React.SetStateAction<IResult[]>>
  setRenderFlag: React.Dispatch<React.SetStateAction<boolean>>
  renderFlag: boolean
}

export const Search = ({
  inputValue,
  setInputValue,
  buttons,
  setButtons,
  setRenderFlag,
  renderFlag,
}: SearchProps) => {
  const [showClear, setShowClear] = useState(false)
  const { tags, about } = useCentrinnoContext() as IContext
  const searchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
    if (event.target.value.length > 0) {
      setShowClear(true)
    } else {
      setShowClear(false)
    }
  }

  const handleClear = () => {
    setInputValue('')
    setShowClear(false)
  }

  const clickHandler = () => {
    setRenderFlag(!renderFlag)
    setButtons(shuffleResults(buttons))
  }

  const scrollDown = () => {
    window.scrollBy({
      top: 400,
      behavior: 'smooth',
    })
  }

  return (
    <>
      {buttons.length > 0 && (
        <SearchBox>
          <div className="line"></div>
          <div className="text">
            <div>
              <h3>{about?.homeWelcomeTitle}</h3>
            </div>
            <div>
              <p>{about?.homeExplainerText}</p>
              <a href="#filters">
                <Button onClick={scrollDown}>make your selection</Button>
              </a>
            </div>
          </div>
          <div className="wrapper">
            <div className="inner">
              <InputBox
                type="search"
                id="site-search"
                name="q"
                placeholder="search categories/tags"
                value={inputValue}
                onChange={searchHandler}
                autoComplete="one-time-code"
              />
              <AnimatePresence mode="wait">
                {showClear ? (
                  <motion.div
                    initial={hidden}
                    animate={show}
                    exit={hide}
                    key="icon"
                    className="icon close-icon"
                  >
                    <CloseIcon handleClear={handleClear} />
                  </motion.div>
                ) : (
                  <motion.div
                    initial={hidden}
                    animate={show}
                    exit={hide}
                    key="icon-square"
                    className="icon"
                  >
                    <MagnifierIcon />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <Button
              onClick={clickHandler}
              className="small no-hover"
              buttonColor="white"
              backgroundColor="white"
              buttonTextColor={textColor}
              border={0}
            >
              <p>shuffle</p>
            </Button>
          </div>
        </SearchBox>
      )}
    </>
  )
}
