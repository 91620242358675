import styled from 'styled-components'
import {
  Button,
  accentColor,
  backgroundColor,
  fadedWhite,
  secondColor,
  textColor,
  thirdColor,
} from '../../styles'
import { motion } from 'framer-motion'
import { Info } from '../StoryCard/styles'
import img from '../../images/grid-item.png'
import { ButtonRow } from '../StoryContent/Metadata/styles'

export const ButtonContainer = styled(motion.div)`
  position: absolute;
  bottom: 12px;
  right: 12px;
  z-index: 2;
  display: flex;
  flex-direction: row-reverse;
  ${Button} {
    width: 32px;
    height: 32px;
    margin-right: 6px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    p {
      font-size: 10px;
    }
    svg {
      height: 45%;
      width: 40%;
    }
    &:last-child {
      width: auto;
      padding: 0 16px;
    }
  }
`

export const GraphContainerStory = styled(motion.div)`
  position: relative;
  width: calc(100% - 64px);
  height: 95vh;
  background-color: #f5f5f5;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin: 32px;
  overflow: hidden;

  .explainer-popup {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2.5rem;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.65);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    transition: all 0.3s ease;
    z-index: 2;

    div {
      max-width: 500px;
    }

    &.popup-invisible {
      opacity: 0;
      pointer-events: none;
    }

    h3 {
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 2rem;
    }

    button p {
      margin: 0;
    }
  }

  .safe-left,
  .safe-right {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 30px;
    height: 100%;
    background-color: transparent;
  }

  .safe-left {
    left: 0;
  }

  .safe-right {
    right: 0;
  }

  ${Info} {
    max-width: 40%;
    height: calc(100% - 24px);
    bottom: 12px;
    left: 12px;
    padding: 0;

    .info {
      padding: 12px 12px 20px 12px;

      .info-header {
        display: flex;
        gap: 8px;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 1.5rem;

        ${Button} {
          width: 80px;
          height: 80px;
        }
      }
      .container-img {
        width: 30%;
        aspect-ratio: 1;
        height: auto;
        overflow: hidden;
      }
      img {
        width: 100%;
        aspect-ratio: 1;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;
      }

      h3,
      p {
        display: block;
      }

      p {
        width: 90%;
      }

      ${ButtonRow} {
        margin-top: 1rem;
        margin-left: 0;
        ${Button} {
          aspect-ratio: unset;
          margin-left: 0;
          width: auto;
          height: auto;
          padding: 4px 8px;

          p {
            width: 100%;
          }
        }
      }

      .labels {
        pointer-events: none;
        top: 12px;
        right: 12px;

        p {
          padding-left: 12px;
          font-size: 12px;

          &::before {
            left: 0;
            top: 5px;
            width: 7px;
            height: 7px;
          }
        }
      }
    }
    h3 {
      font-size: 24px;
    }
    button {
      width: 120px;

      svg {
        width: 40px;
        height: 32px;
      }
    }
  }
  .force-graph {
    width: 100%;
    height: 100%;
    background-image: url(${img});
    background-size: 8px;

    svg {
      overflow: visible;
      cursor: move;
    }

    p {
      padding: 4px;
      background-color: ${backgroundColor};
      border-radius: 4px;
      font-size: 8px;
      margin-bottom: 0;
      text-align: center;
      user-select: none;
      cursor: grab;

      &.category-node {
        border: 1px solid ${accentColor};
      }

      &.tag-node {
        border: 1px solid ${secondColor};
      }

      &.story-node {
        border: 1px solid ${thirdColor};
      }

      &.main-story-node {
        font-size: 12px;
        background-color: ${textColor};
        color: ${backgroundColor};
      }
    }

    .node-image {
      width: 100%;
      height: 100%;
      border-radius: 3px;
      user-select: none;
      cursor: grab;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;
      }
    }

    circle {
      cursor: grab;
    }
  }

  .graph-info {
    .labels {
      left: unset;
      bottom: 6px;
      right: 6px;
      justify-content: flex-end;
    }

    &.legenda {
      .info {
        background-color: ${fadedWhite};
        backdrop-filter: blur(5px);

        ul {
          margin: 0.5em 0.25em;
          padding-left: 1em;

          li {
            position: relative;
            list-style: none;
            font-size: 11px;

            &::before {
              content: '';
              position: absolute;
              top: 50%;
              transform: translate(0, -50%);
              left: -1.2em;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: ${secondColor};
            }

            &:first-child {
              &::before {
                background-color: ${accentColor};
              }
            }

            &:last-child {
              &::before {
                background-color: ${thirdColor};
              }
            }
          }
        }
      }
    }
  }

  .bar {
    width: 14px;
    height: 2px;
    background-color: ${textColor};
  }

  @media screen and (max-width: 1150px) {
    width: calc(100% - 48px);
    margin: 24px;
  }

  @media screen and (max-width: 900px) {
    ${Info} {
      height: auto;
      max-width: 70%;

      .info {
        .info-header {
          margin: 0;
          .container-img {
            display: none;
          }

          ${Button} {
            width: auto;
            aspect-ratio: 1;
            height: 100%;
            position: absolute;
            right: -8px;
            top: 0;
            transform: translateX(100%);
          }
        }

        h3,
        p {
          display: -webkit-box;
        }

        ${ButtonRow} {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    margin: 16px 8px;
    height: 65vh;
    width: calc(100% - 16px);

    ${Info} {
      width: calc(100% - 16px);
      min-height: 80px;
      max-width: 70%;
      bottom: 8px;
      left: 8px;

      .info {
        padding: 8px 6px 8px 6px;

        p {
          display: none;
          &.story,
          &.tag,
          &.cat {
            display: block;
            width: auto;
          }
        }

        .labels {
          top: unset;
          bottom: 4px;
          right: 4px;

          p {
            padding-left: 10px;
            font-size: 10px;

            &::before {
              top: 6px;
              width: 4px;
              height: 4px;
            }
          }
        }
      }

      h3 {
        font-size: 18px;
      }

      button {
        width: 80px;
        height: 80px;

        svg {
          width: 20px;
          height: 16px;
        }
      }
    }
    .force-graph {
      background-size: 10px;

      p {
        font-size: 10px;
        padding: 2px;

        &.main-story-node {
          font-size: 8px;
        }
      }
    }
  }

  ${ButtonContainer} {
    ${Button} {
      &:first-child {
        margin-right: 0;
      }
    }
    @media only screen and (max-width: 800px) {
      bottom: unset;
      top: 12px;
      right: 12px;
    }
  }
`
