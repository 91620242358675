import { PortableText } from "@portabletext/react"
import type { TypedObject } from "@portabletext/types"
import { TextElement } from "./styles"

interface TextRightProps {
  header: string
  textContent: TypedObject
  className: string
}

function TextColumn({ header, textContent, className }: TextRightProps) {
  return (
    <TextElement className={className}>
      <div className="container">
        <div className={`${!header ? "header no-margin" : "header"}`}>
          <h3>{header}</h3>
        </div>
        <div className="text-content">
          <PortableText value={textContent} />
        </div>
      </div>
    </TextElement>
  )
}

export default TextColumn
