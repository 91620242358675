import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useCentrinnoContext } from '../../context/storyContext'
import { IContext, IStory, IBlock } from '../../types/types'
import { StoryBody, StoryPage } from './styles'
import { slugify } from '../../helpers/slugify'
import TextColumn from '../FlexibleContent/TextColumn'
import TwoImages from '../FlexibleContent/TwoImages'
import Gallery from '../FlexibleContent/Gallery'
import AudioPlayer from '../FlexibleContent/AudioPlayer'
import VideoPlayer from '../FlexibleContent/VideoPlayer'
import { ForceGraphStory } from '../ForceGraphStory'
import Hero from '../StoryContent/Hero/Hero'
import MetaData from '../StoryContent/Metadata/MetaData'
import { useRecoilState } from 'recoil'
import { isAnimationOnState, isExitDelayedState } from '../../helpers/atoms'
import {
  down,
  noExit,
  normal,
  out,
  transition,
  transitionDelayed,
} from '../../helpers/animationVariants'
import ProgressBar from '../StoryContent/Progress/Progress'
import Divider from '../FlexibleContent/DIvider/Divider'
import NextHero from '../StoryContent/Hero/NextHero'
import { Helmet } from 'react-helmet-async'
import PdfFile from '../FlexibleContent/PdfFile'

export const Story = () => {
  const { stories } = useCentrinnoContext() as IContext
  const [nextStory, setNextStory] = useState<IStory | undefined>({} as IStory)
  const [story, setStory] = useState<IStory | undefined>({} as IStory)
  const [isAnimationOn, setIsAnimationOn] = useRecoilState(isAnimationOnState)
  const [isExitDelayed, setIsExitDelayed] = useRecoilState(isExitDelayedState)
  const [progress, setProgress] = useState(0)
  const [navigateFlag, setNavigateFlag] = useState(true)
  const navigate = useNavigate()
  const containerRef = useRef(null)

  const { slug } = useParams()
  useEffect(() => {
    setStory(stories.find((story: IStory) => slugify(story.title) === slug))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stories])

  const { state } = useLocation()
  const { image, title } = state !== null && state // Read values passed on state

  useEffect(() => {
    setIsExitDelayed(false)
    // containerRef && containerRef.current && containerRef.current?.scrollBy(0, 2)
  }, [])

  useEffect(() => {
    stories.find((item, index, arr) => {
      if (story?.title === item.title) {
        if (arr.length - 1 === index) {
          setNextStory(arr[0])
        } else {
          setNextStory(arr[index + 1])
        }
      }
      return null
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [story])

  const renderFlexibleContent = () => {
    // eslint-disable-next-line array-callback-return
    const flexibleElements = story?.flexibleContent?.map((object: IBlock) => {
      switch (object._type) {
        case 'textColumnRight':
          return (
            <TextColumn
              header={object.header}
              textContent={object.textContent}
              className={'text-right'}
              key={object._key}
            />
          )
        case 'textColumnLeft':
          return (
            <TextColumn
              header={object.header}
              textContent={object.textContent}
              className={'text-left'}
              key={object._key}
            />
          )
        case 'twoImages':
          return (
            <TwoImages
              key={object._key}
              imgOne={object.imageOne}
              imgTwo={object.imageTwo}
              className={'two-images'}
              title={story?.title}
            />
          )
        case 'imageGallery':
          return (
            <Gallery
              gallery={object.gallery}
              title={story?.title}
              key={object._key}
            />
          )

        case 'audio':
          return (
            <AudioPlayer
              assetRefParts={object.audio.asset._ref.split('-')}
              key={object._key}
            />
          )
        case 'customVideo':
          return (
            <VideoPlayer
              key={object._key}
              videoRefParts={object.videoFile.asset._ref.split('-')}
            />
          )
        case 'video':
          return <VideoPlayer videoUrl={object.videoUrl} key={object._key} />
        case 'dividerLine':
          return <Divider key={object._key} />
        case 'fileUpload':
          return (
            <PdfFile
              assetRefParts={object.uploadedFile.asset._ref.split('-')}
            ></PdfFile>
          )
      }
    })
    return flexibleElements
  }

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const element = document.getElementById('next-story-hero')
    const container = event.currentTarget

    if (!element || !container) return

    const elementHeight = element.offsetHeight
    const containerHeight = container.offsetHeight
    const containerScrollHeight = container.scrollHeight
    const containerScrollTop = container.scrollTop
    const scrollDistanceFromTop = containerScrollTop + containerHeight
    const remainingDistance = containerScrollHeight - scrollDistanceFromTop

    const scrollProgress = 1 - remainingDistance / elementHeight
    setProgress(scrollProgress)

    if (scrollProgress >= 0.99) {
      setIsAnimationOn(false)
      if (navigateFlag) {
        setNavigateFlag(false)
        let timer = setTimeout(() => {
          navigate(`/story/${slugify(nextStory?.title as string)}`, {
            state: { image: nextStory?.heroImage, title: nextStory?.title },
          })
        }, 800)

        return () => {
          clearTimeout(timer)
        }
      }
    }
  }

  return (
    <StoryPage
      initial={isAnimationOn ? down : normal}
      animate={isAnimationOn ? normal : normal}
      transition={isExitDelayed ? transitionDelayed : transition}
      exit={isAnimationOn ? out : noExit}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${story?.title} - The Living Archive`}</title>
        <meta
          name="title"
          content={`${story?.title} - The Living Archive`}
          data-react-helmet="true"
        />
        <meta
          name="description"
          content={`${story?.summaryText}`}
          data-react-helmet="true"
        />
      </Helmet>
      {story && (
        <StoryBody
          id="story-container"
          ref={containerRef}
          onScroll={handleScroll}
        >
          {state ? (
            <Hero title={title} image={image} />
          ) : (
            <Hero title={story.title} image={story.heroImage} />
          )}
          {story.metaData && (
            <MetaData
              subject={story.metaData.subject}
              location={story.metaData.pilotLocation}
              method={story.metaData.collectionMethod}
              date={story.metaData.dateOfEvents}
              affectedLocations={story.metaData.locations}
              tags={story.tags}
              title={story.metaData.title}
            />
          )}
          {story.title && <ForceGraphStory selectedVariable={story} />}
          {renderFlexibleContent()}
          {nextStory && (
            <>
              <ProgressBar progress={progress} />
              <NextHero
                title={nextStory?.title}
                link={`/story/${slugify(nextStory?.title)}`}
                image={nextStory?.heroImage}
              />
            </>
          )}
        </StoryBody>
      )}
    </StoryPage>
  )
}
