import styled from 'styled-components'
import { backgroundColor, textColor } from '../../styles'
import { motion } from 'framer-motion'
import { IntroSection } from '../Intro/styles'
import { DividerLine } from '../FlexibleContent/DIvider/styles'

export const AboutMain = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 119;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: ${backgroundColor};
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  ${IntroSection} {
    .intro-image {
      width: 367px;
      h2 {
        width: 100%;
        margin-bottom: 0;
        color: ${backgroundColor};
      }
    }


    h1 {
      margin-bottom: -24px;
    }

    @media screen and (max-width: 1150px) {
      h1 {
        line-height: 1;
      }

      .intro-image {
        width: 60%;
      }

      h2 {
        margin-bottom: 1em;
        width: 80%;
      }
    }

    @media screen and (max-width: 500px) {
    
      .intro-image {
        width: 100%;
      }

    }
  }

  ${DividerLine} {
    &:first-child {
      padding-top: 0;
    }
  }
`
