/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useCentrinnoContext } from '../../context/storyContext'
import { runForceGraphStory } from '../../helpers/runForceGraphStory'
import { slugify } from '../../helpers/slugify'
import {
  Button,
  backgroundColor,
  fadedWhite,
  secondColor,
  textColor,
} from '../../styles'
import { IContext, IStory, DData, IPreference } from '../../types/types'
import { ButtonContainer, GraphContainerStory } from './styles'
import { AnimatePresence, motion } from 'framer-motion'
import { hiddenDown, hide, showAndRaise } from '../../helpers/animationVariants'
import { Info } from '../StoryCard/styles'
import { isAnimationOnState } from '../../helpers/atoms'
import { useRecoilState } from 'recoil'
import ImgHandler from '../ImgHandler'
import { ButtonRow } from '../StoryContent/Metadata/styles'

interface ForceGraphStroyProps {
  selectedVariable: IStory
}

export const ForceGraphStory = ({ selectedVariable }: ForceGraphStroyProps) => {
  const containerRef = useRef(null)
  const { stories, about } = useCentrinnoContext() as IContext
  const [displayCta, setDisplayCta] = useState(false)
  const [displayNodeDescription, setDisplayNodeDescription] = useState(false)
  const [nodeData, setNodeData] = useState<DData>({})
  const [isRemoved, setIsRemoved] = useState(false)
  const isStory = nodeData && nodeData.class === 'story-node'
  const [, setIsAnimationOn] = useRecoilState(isAnimationOnState)
  // const [categories, setCategories] = useState<string[]>()

  const renderLabel = () => {
    switch (nodeData.class) {
      case 'category-node':
        return <p className="cat">category</p>
      case 'tag-node':
        return <p className="tag">tag</p>
      case 'story-node':
        return <p className="story">story</p>
    }
  }

  useEffect(() => {
    setDisplayNodeDescription(false)

    console.log(selectedVariable.tags)

    let timer1 = setTimeout(() => {
      if (containerRef.current) {
        runForceGraphStory(
          containerRef.current,
          setDisplayCta,
          setNodeData,
          setDisplayNodeDescription,
          selectedVariable,
          stories
        )
      }
    }, 1000)

    setDisplayCta(false)

    return () => {
      clearTimeout(timer1)
    }
  }, [selectedVariable.title])

  // useEffect(() => {
  //   const tagCats =
  //     selectedVariable.tags &&
  //     selectedVariable.tags.length > 1 &&
  //     selectedVariable.tags
  //       .slice(0, 8)
  //       .map((tag: IPreference, index: number) => tag?.category?.title)

  //   setCategories(tagCats as string[])
  // }, [selectedVariable.tags])

  const handleStoryClick = () => {
    setIsAnimationOn(true)
  }

  const removePopup = () => {
    setIsRemoved(true)
  }

  return (
    <GraphContainerStory>
      <div className="safe-left"></div>
      <div
        className={`${
          isRemoved ? 'popup-invisible' : 'popup-visible'
        } explainer-popup`}
        onClick={removePopup}
      >
        <div>
          <h3>{about?.graphExplainerTitle}</h3>
          <p>{about?.graphExplainerText}</p>

          <Button
            backgroundColor={secondColor}
            buttonColor={secondColor}
            color={textColor}
            onClick={removePopup}
          >
            <p>click to continue</p>
          </Button>
        </div>
      </div>
      <div ref={containerRef} className="force-graph"></div>
      <div className="safe-right"></div>
      <AnimatePresence>
        {displayNodeDescription && (
          <Info
            initial={hiddenDown}
            animate={showAndRaise}
            exit={hide}
            className="graph-info"
            key="info"
          >
            <div className="info story-info">
              <div className="info-header">
              {nodeData.heroImage && (
                  <div className="container-img">
                    <img src={nodeData.heroImage} alt="" />
                  </div>
                )}
                {displayCta && (
                  <Link
                    to={
                      isStory
                        ? `/story/${slugify(nodeData.name as string)}`
                        : ''
                    }
                    onClick={handleStoryClick}
                  >
                    <Button
                      backgroundColor={fadedWhite}
                      buttonColor={secondColor}
                      className="arrow"
                    >
                      <svg
                        width="23"
                        height="15"
                        viewBox="0 0 23 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <motion.path
                          d="M21.503 1.9772L11.492 11.9882L1.48095 1.9772"
                          stroke={secondColor}
                          strokeWidth="2"
                        />
                      </svg>
                    </Button>
                  </Link>
                )}
              </div>

              <h3>{nodeData.name}</h3>
              <p>
                {nodeData.definition
                  ? nodeData.definition
                  : nodeData.summary
                  ? nodeData.summary
                  : ''}

      
              </p>

              <ButtonRow>
                {nodeData.tags &&
                  nodeData.tags.map(
                    (tag: IPreference, index: number) => (
                      <Button
                        key={index}
                        buttonTextColor={textColor}
                        buttonColor={secondColor}
                        backgroundColor={secondColor}
                        className="small fake"
                      >
                        <p>{tag.title}</p>
                      </Button>
                    )
                  )}
              </ButtonRow>
              <div className="labels">{renderLabel()}</div>
            </div>
          </Info>
        )}
      </AnimatePresence>

      <ButtonContainer>
        <Button
          className="zoom-button medium"
          backgroundColor={backgroundColor}
          buttonColor={secondColor}
          id="zoom-in"
        >
          <svg
            width="15"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.983398 14.6211L14.1768 1.42771"
              stroke={textColor}
              strokeWidth="2"
            />
            <path
              d="M0.983398 1.42725L14.1768 14.6206"
              stroke={textColor}
              strokeWidth="2"
            />
          </svg>
        </Button>
        <Button
          className="zoom-button medium"
          backgroundColor={backgroundColor}
          buttonColor={secondColor}
          id="zoom-out"
        >
          <div className="bar"></div>
        </Button>
        <Button
          className="zoom-button medium"
          backgroundColor={backgroundColor}
          buttonColor={secondColor}
          buttonTextColor={textColor}
          id="zoom-init"
        >
          <p>reset</p>
        </Button>
      </ButtonContainer>
    </GraphContainerStory>
  )
}
