import { useEffect, useState } from 'react'
import { useCentrinnoContext } from '../../context/storyContext'
import {
  getConnectedStories,
  useSelectedVariables,
} from '../../hooks/useRelatedStories'
import {
  Button,
  accentColor,
  backgroundColor,
  secondColor,
  textColor,
} from '../../styles'
import { IContext, IPreference, IResult, IStory } from '../../types/types'
import { ResultsSection, ResultsWrapper, SelectedRow } from './styles'
import { AnimatePresence, motion } from 'framer-motion'
import {
  hidden,
  hideDelay,
  show,
  showDelay,
} from '../../helpers/animationVariants'

interface ResultsProps {
  buttons: IResult[]
  setButtons: React.Dispatch<React.SetStateAction<IResult[]>>
  setSelectedVariables: React.Dispatch<React.SetStateAction<IPreference[]>>
  setRelatedStories: React.Dispatch<React.SetStateAction<IStory[]>>
}

const Results = ({
  buttons,
  setButtons,
  setSelectedVariables,
  setRelatedStories,
}: ResultsProps) => {
  const { stories } = useCentrinnoContext() as IContext
  const [prefrencesSelected, setPrefrencesSelected] = useState<IResult[]>([])
  const [toggleSignal, setToggleSignal] = useState(false)

  const selectedVariables = useSelectedVariables(prefrencesSelected)

  const handleFilterStories = (item: IResult) => {
    setToggleSignal(!toggleSignal)

    item.isSelected = !item.isSelected

    !item.isSelected &&
      prefrencesSelected.some(
        (preference) => preference.result === item.result
      ) &&
      setPrefrencesSelected(
        prefrencesSelected.filter(
          (preference) => preference.result !== item.result
        )
      )

    item.isSelected && setPrefrencesSelected((prev) => [...prev, item])
  }

  const handleResetFilterStories = (item: IResult) => {
    item.isSelected = !item.isSelected

    setTimeout(() => {
      item.isSelected && setPrefrencesSelected((prev) => [...prev, item])
    }, 800)

    !item.isSelected &&
      prefrencesSelected.some(
        (preference) => preference.result === item.result
      ) &&
      setPrefrencesSelected(
        prefrencesSelected.filter(
          (preference) => preference.result !== item.result
        )
      )

    setToggleSignal(!toggleSignal)
  }

  const clearFilters = () => {
    const clearButtons = buttons.map((item) => {
      return {
        result: item.result,
        isSelected: false,
        isTag: item.isTag,
      }
    })
    setPrefrencesSelected([])
    setButtons(clearButtons)
  }

  useEffect(() => {
    selectedVariables && setSelectedVariables(selectedVariables)
    selectedVariables.length > 0 &&
      setRelatedStories(getConnectedStories(selectedVariables, stories))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefrencesSelected, stories, toggleSignal])

  return (
    <div id='#filters'>
      <ResultsWrapper>
        <ResultsSection>
          <AnimatePresence>
            {buttons &&
              buttons.map((item: IResult, index: number) => (
                <motion.div
                  initial={hidden}
                  animate={showDelay}
                  exit={hideDelay}
                  key={index}
                  className="filter-element"
                >
                  <Button
                    key={index}
                    className={`${item.isSelected ? 'selected' : ''}`}
                    onClick={() => handleFilterStories(item)}
                    buttonTextColor={item.isTag ? textColor : backgroundColor}
                    buttonColor={item.isTag ? secondColor : accentColor}
                    backgroundColor={item.isTag ? secondColor : accentColor}
                    border={0}
                  >
                    <p>{item.result}</p>
                  </Button>
                </motion.div>
              ))}
            {buttons.length === 0 && (
              <p>
                there are no tags or categories corresponding to the search
                criteria
              </p>
            )}
          </AnimatePresence>
        </ResultsSection>
      </ResultsWrapper>

      <SelectedRow>
        <div className="wrapper">
          <div className="inner">
            <AnimatePresence>
              {prefrencesSelected &&
                prefrencesSelected.map((item: IResult, index: number) => (
                  <motion.div
                    initial={hidden}
                    animate={show}
                    exit={{ opacity: 0, transition: { duration: 0.3 } }}
                    key={index}
                    className="filter-element"
                  >
                    <Button
                      key={index}
                      onClick={() => handleResetFilterStories(item)}
                      className="pill"
                      buttonTextColor={backgroundColor}
                      buttonColor={textColor}
                      backgroundColor={textColor}
                      border={0}
                    >
                      <p>{item.result}</p>
                      <span>
                        <svg
                          width="10"
                          height="9"
                          viewBox="0 0 15 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.983398 14.6211L14.1768 1.42771"
                            stroke="#1E1E1E"
                            strokeWidth="2"
                          />
                          <path
                            d="M0.983398 1.42725L14.1768 14.6206"
                            stroke="#1E1E1E"
                            strokeWidth="2"
                          />
                        </svg>
                      </span>
                    </Button>
                  </motion.div>
                ))}
            </AnimatePresence>
            <AnimatePresence>
              {prefrencesSelected && prefrencesSelected.length > 0 && (
                <Button
                  onClick={clearFilters}
                  className="small clear no-hover"
                  buttonColor="white"
                  backgroundColor="white"
                  buttonTextColor={textColor}
                >
                  <p>clear all</p>
                </Button>
              )}
            </AnimatePresence>
          </div>
        </div>
      </SelectedRow>
    </div>
  )
}

export default Results
