import { Link, useLocation } from 'react-router-dom'
import { Button, accentColor, secondColor, thirdColor } from '../../styles'
import { NavigationBox } from './styles'
import { useRecoilState } from 'recoil'
import { isAnimationOnState, isExitDelayedState } from '../../helpers/atoms'
import { useState } from 'react'
import { motion } from 'framer-motion'
import { isMobile } from '../../helpers/windowHelpers'
import { hide } from '../../helpers/animationVariants'

const Navigation = () => {
  const location = useLocation()
  const isHome = location.pathname === '/'
  const [openMenu, setOpenMenu] = useState(false)
  const [, setIsAnimationOn] = useRecoilState(isAnimationOnState)
  const [, setIsExitDelayed] = useRecoilState(isExitDelayedState)
  const handleHomeClick = () => {
    setIsAnimationOn(true)
  }

  const openMenuHandler = () => {
    setOpenMenu(!openMenu)
  }

  const handleAboutClick = () => {
    if (location.pathname.includes('story')) {
      setIsExitDelayed(true)
    } else {
      setIsExitDelayed(false)
    }
  }

  const transition = {
    duration: 0.4,
    delay: 0.2,
    ease: 'easeInOut',
  }

  const offScreen = {
    opacity: 0,
    y: '-100%',
  }

  const inScreen = {
    opacity: 1,
    y: 0,
  }

  return (
    <NavigationBox>
      <div className="container">
        <Button className="small mobile-menu" onClick={openMenuHandler}>
          {openMenu ? <p>close</p> : <p>menu</p>}
        </Button>
        {!isHome && (
          <motion.div
            initial={isMobile ? offScreen : { opacity: 1, y: 0 }}
            animate={isMobile ? (openMenu ? inScreen : offScreen) : inScreen}
            exit={hide}
            transition={transition}
          >
            <Link to={`/`} onClick={handleHomeClick}>
              <Button className="small nav-button">
                <p>home</p>
                <svg
                  width="145"
                  height="117"
                  viewBox="0 0 145 117"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.879883 39.7888V116.289H41.593V78.2888C41.593 78.2888 44.1062 52.2888 72.2536 52.2888C100.401 52.2888 102.914 78.2888 102.914 78.2888V116.289H144.13V39.7888L72.2536 0.288818L0.879883 39.7888Z"
                    fill={accentColor}
                  />
                </svg>
              </Button>
            </Link>
          </motion.div>
        )}
        <motion.div
          initial={isMobile ? offScreen : { opacity: 1, y: 0 }}
          animate={isMobile ? (openMenu ? inScreen : offScreen) : inScreen}
          transition={transition}
        >
          <Link to={`/about`} onClick={handleAboutClick}>
            <Button className="small nav-button ">
              <p>about</p>
              <svg
                width="142"
                height="102"
                viewBox="0 0 142 102"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.629883 14.2888V101.289H141.63V14.2888L124.508 0.288818L106.883 14.2888L89.2585 0.288818L71.1299 14.2888L53.0013 0.288818L34.3692 14.2888L17.7513 0.288818L0.629883 14.2888Z"
                  fill={secondColor as string}
                />
              </svg>
            </Button>
          </Link>
        </motion.div>
        <motion.div
          initial={isMobile ? offScreen : { opacity: 1, y: 0 }}
          animate={isMobile ? (openMenu ? inScreen : offScreen) : inScreen}
          transition={transition}
        >
          <Link to={`/index`}>
            <Button className="small nav-button">
              <p>index</p>
              <svg
                width="116"
                height="108"
                viewBox="0 0 116 108"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M56.25 0.289062C64.8104 0.289062 71.75 7.22865 71.75 15.7891V15.7891C71.75 24.3495 64.8104 31.2891 56.25 31.2891L0.60547 31.2891L0.605472 0.289061L56.25 0.289062Z"
                  fill={thirdColor}
                />
                <path
                  d="M81.0078 38.2891L100.25 53.7891L81.0078 69.2891L0.605939 69.2891L0.605942 38.2891L81.0078 38.2891Z"
                  fill={thirdColor}
                />
                <rect
                  x="116"
                  y="76.2891"
                  width="31"
                  height="115.395"
                  transform="rotate(90 116 76.2891)"
                  fill={thirdColor}
                />
              </svg>
            </Button>
          </Link>
        </motion.div>
        <motion.div
          initial={isMobile ? offScreen : { opacity: 1, y: 0 }}
          animate={isMobile ? (openMenu ? inScreen : offScreen) : inScreen}
          transition={transition}
        >
          <Link to={`/contribute`}>
            <Button className="small nav-button mr-0">
              <p>contribute</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="15"
                viewBox="0 0 16 15"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.32543 0.876953L15.6713 7.11399L13.7296 9.40088L9.82543 6.08603V14.0947H6.82543V6.08603L2.92128 9.40088L0.979584 7.11399L8.32543 0.876953Z"
                  fill="#0083C5"
                />
              </svg>
            </Button>
          </Link>
        </motion.div>
      </div>
    </NavigationBox>
  )
}

export default Navigation
