import React, { SyntheticEvent, useEffect, useRef, useState } from "react"
import Range from "../../Range"
import Seeker from "../../Seeker"
import { Button, backgroundColor, textColor } from "../../../styles"
import { RangeContainer } from "./styles"

interface AudioProps {
  assetRefParts: string[]
}

const AudioPlayer = ({ assetRefParts }: AudioProps) => {
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [volume, setVolume] = useState(1)
  const audioRef = useRef<HTMLAudioElement>(null)

  useEffect(() => {
    if (isPlaying && audioRef.current) {
      audioRef.current.play()
    } else if (audioRef.current) {
      audioRef.current.pause()
    }
  }, [audioRef, isPlaying])

  useEffect(() => {
    if (audioRef.current) audioRef.current.volume = volume
  }, [audioRef, volume])

  const handleTrackClick = (position: number) => {
    if (audioRef.current) audioRef.current.currentTime = position
  }

  const id = assetRefParts[1] // "ff7..."
  const format = assetRefParts[2] // "m4a"
  const assetUrl = `https://cdn.sanity.io/files/${process.env.REACT_APP_SANITY_ID}/production/${id}.${format}`
  return (
    <RangeContainer>
      <Button
        onClick={() => setIsPlaying(!isPlaying)}
        buttonColor={backgroundColor}
        buttonTextColor={textColor}
        backgroundColor={backgroundColor}
      >
        {isPlaying ? "Pause" : "Play"}
      </Button>
      <audio
        ref={audioRef}
        onLoadedMetadata={(e: SyntheticEvent<HTMLAudioElement>) =>
          setDuration((e.target as HTMLAudioElement).duration)
        }
        onTimeUpdate={(e: SyntheticEvent<HTMLAudioElement>) =>
          setCurrentTime((e.target as HTMLAudioElement).currentTime)
        }
      >
        <source src={assetUrl} type="audio/mpeg" />
      </audio>
      <Seeker
        currentTime={currentTime}
        duration={duration}
        handleTrackClick={handleTrackClick}
      />
      <Range
        max={1}
        value={volume}
        handleChange={setVolume}
        min={0}
        isVolume={true}
      />
    </RangeContainer>
  )
}

export default AudioPlayer
