import React, { useState, useEffect, useContext, ReactNode } from "react"
import client from "../sanityService"
import { IPreference, IContext, IStory, IAbout, IContribute } from "../types/types"
import { shuffleStories } from "../helpers/shuffle"

const CentrinnoContext = React.createContext<IContext | null>(null)
export const useCentrinnoContext = () => useContext(CentrinnoContext)

interface IStoryProvider {
  children: ReactNode
}

export function CentrinnoProvider({ children }: IStoryProvider) {
  const [stories, setStories] = useState<IStory[]>([])
  const [about, setAbout] = useState<IAbout>()
  const [contribute, setContribute] = useState<IContribute>()
  const [tags, setTags] = useState<IPreference[]>([])
  const [categories, setCategories] = useState<IPreference[]>([])
  const [loader, setLoader] = useState(false)
  useEffect(() => {
    async function fetchStories() {
      setLoader(true)

      const storyQuery = `*[_type == "story"]{
        ...,
        "tags": tags[]->{title, definition,"category":categories->{title, definition}}
      }`
      const categoryQuery = `*[_type=="category"]{
        title,
        definition, 
        "relatedTags": *[_type=='tag' && references(^._id)]{ 
          title,
          definition
        }
      }`
      const aboutQuery = `*[_type=="aboutPage"][0]`
      const contributeQuery = `*[_type=="contributePage"][0]`

      const catData = await client.fetch(categoryQuery)
      const aboutData = await client.fetch(aboutQuery)
      const contributeData = await client.fetch(contributeQuery)
      const filteredCategories = catData.filter(
        (cat: IPreference) => cat !== null
      )
      const storyData = await client.fetch(storyQuery)
      const filteredStoryData = storyData.filter(
        (story: IStory) => story !== null
      )
      filteredStoryData?.forEach((story: IStory) => {
        const filteredTags = story.tags.filter((tag) => {
          return tag
        })
        story.tags = filteredTags
      })

      const tagQuery: IPreference[] = []
      filteredStoryData.forEach((story: IStory) =>
        story.tags.forEach((tag: IPreference) => {
          if (tag && tag !== null && tag.title) {
            tagQuery.push(tag)
          }
        })
      )
      setStories(filteredStoryData)
      setTags(tagQuery)
      setCategories(filteredCategories)
      setAbout(aboutData)
      setContribute(contributeData)
      setLoader(false)
    }
    fetchStories()
  }, [])

  return (
    <CentrinnoContext.Provider
      value={{ stories, tags, categories, loader, about, contribute }}
    >
      {children}
    </CentrinnoContext.Provider>
  )
}
