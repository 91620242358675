import { IResult, IStory } from "../types/types"

export const searchButtons = (input: string, results: IResult[]) => {
  let resultButtons: IResult[] = []

  results &&
    results.forEach((result) => {
      result?.result?.toLowerCase().includes(input.toLowerCase()) &&
        resultButtons.push(result)
    })
  return resultButtons
}

export const SearchStories = (input: string, stories: IStory[]) => {
  let searchStories: IStory[] = []
  const lowerCaseInput = input.toLocaleLowerCase()
  stories &&
    stories.forEach((story) => {
      story.title?.toLowerCase().includes(lowerCaseInput) &&
        searchStories.push(story)
    })

  return searchStories
}

export const filterOnPilots = (input: string, stories: IStory[]) => {
  let searchStories: IStory[] = []
  const lowerCaseInput = input.toLocaleLowerCase()
  stories &&
    stories.forEach((story) => {
      story.metaData.pilotLocation?.toLowerCase().includes(lowerCaseInput) &&
        searchStories.push(story)
    })

  return searchStories
}
