import * as d3 from 'd3'
import React from 'react'
import { jsonFyStory } from '../data/graphDataStory'
import { DNode, IStory, DData, IPreference } from '../types/types'
import { isMobile } from './windowHelpers'
import { accentColor, textColor } from '../styles'
import { SimulationLinkDatum, SimulationNodeDatum } from 'd3'

export function runForceGraphStory(
  container: HTMLElement,
  setDisplayCta: React.Dispatch<React.SetStateAction<boolean>>,
  setNodeData: React.Dispatch<React.SetStateAction<DData>>,
  setDisplayNodeDescription: React.Dispatch<React.SetStateAction<boolean>>,
  selectedVariable: IStory,
  stories: IStory[]
) {
  const data = jsonFyStory(selectedVariable, stories)
  const links = data.links.map((d) => d)
  const nodes = data.nodes.map((d: any) => d)
  const containerRect = container.getBoundingClientRect()
  const height = containerRect.height
  const width = containerRect.width

  function dragstarted() {
    // @ts-ignore

    d3.select(this).classed('fixing', true)
    setDisplayCta(false)
    setDisplayNodeDescription(false)
    setNodeData({})
    simulation.alpha(1).restart()
  }

  function dragged(event: DragEvent, d: any) {
    d.fx = event.x
    d.fy = event.y
    // simulation.alpha(1).restart()
    setDisplayNodeDescription(true)
    d.class === 'story-node' && setDisplayCta(true)
    link
      .filter(
        (l) => (l.source as any).id === d.id || (l.target as any).id === d.id
      )
      .attr('stroke', accentColor)
      .attr('stroke-opacity', 1)
      .attr('stroke-width', 1.5)

    setNodeData({
      name: d.name as string,
      class: d.class as string,
      tags: d.tags as IPreference[],
      definition: d.definition as string,
      summary: d.summary as string,
      heroImage: d.image as string,
    })
  }

  //   dragended function in case we move away from sticky dragging!
  function dragended(event: DragEvent, d: DNode) {
    // @ts-ignore
    d3.select(this).classed('fixed', true)
    simulation.alpha(1).restart()
  }

  function click(event: TouchEvent, d: any) {
    delete d.fx
    delete d.fy
    // @ts-ignore
    d3.select(this).classed('fixed', false)
    // @ts-ignore
    d3.select(this).classed('fixing', false)
    simulation.alpha(1).restart()
    link
      .filter(
        (l) => (l.source as any).id === d.id || (l.target as any).id === d.id
      )
      .attr('stroke', textColor)
      .attr('stroke-opacity', 0.2)
      .attr('stroke-width', 1)
  }

  const simulation = d3
    .forceSimulation(nodes as any[])
    .force(
      'link',
      d3
        .forceLink(links as SimulationLinkDatum<SimulationNodeDatum>[])
        .id((d: any) => d.id)
    )
    .force('charge', d3.forceManyBody().strength(isMobile ? -2500 : -2000))
    // .force('collision', d3.forceCollide().radius(isMobile ? 5 : 20))
    .force('x', d3.forceX())
    .force('y', d3.forceY())

  if (container.children) {
    d3.select(container).selectAll('*').remove()
  }

  const zoomSvg = d3.zoom().on('zoom', (event) => {
    group.attr('transform', event.transform).on('wheel.zoom', null)
  })

  const zoom = d3
    .zoom()
    .on('zoom', (event) => {
      group.attr('transform', event.transform)
    })
    .scaleExtent([0.2, 100])

  const svg = d3
    .select(container)
    .append('svg')
    .attr('viewBox', [-width / 2, -height / 2, width, height])
    .call(zoomSvg as any)
    .on('wheel.zoom', null)

  const group = svg
    .append('g')
    .classed('graph-group', true)
    .attr('width', '100%')
    .attr('height', '100%')
    .call(zoomSvg as any)
    .on('wheel.zoom', null)

  const link = group
    .append('g')
    .attr('stroke', '#1e1e1e')
    .attr('stroke-opacity', 0.2)
    .selectAll('line')
    .data(links)
    .join('line')

  const node = group
    .append('g')
    .selectAll<SVGCircleElement, { x: number; y: number }>('g')
    .data(nodes)
    .join('g')
    .classed('node', true)
    .classed('fixed', (d: any) => d.fx !== undefined)
    .attr('class', (d: any) => d.class as string)
    .call(
      d3
        .drag()
        .on('start', dragstarted)
        .on('drag', dragged as any)
        .on('end', dragended as any) as any
    )
    .on('click', click as any)

  d3.selectAll('.category-node')
    .append('circle')
    .attr('fill', '#0083C5')
    .attr('r', 7)

  d3.selectAll('.tag-node')
    .append('circle')
    .attr('fill', '#FFC434')
    .attr('r', 7)

  d3.selectAll('.story-node')
    .append('foreignObject')
    .attr('height', 35)
    .attr('width', 35)
    .attr('x', -17)
    .attr('y', -30)
    .attr('r', 30)
    .append('xhtml:div')
    .attr('class', 'node-image')
    .append('xhtml:img')
    .attr('src', (d: any) => d.image)
    .attr('transform-origin', 'center')
    .attr('height', 35)
    .attr('width', 35)

  d3.selectAll('.main-story-node')
    .append('foreignObject')
    .attr('height', 100)
    .attr('width', 100)
    .attr('x', -50)
    .attr('y', -50)
    .attr('r', 100)
    .append('xhtml:div')
    .attr('class', 'node-image')
    .append('xhtml:img')
    .attr('src', (d: any) => d.image)
    .attr('transform-origin', 'center')
    .attr('height', 100)
    .attr('width', 100)

  node
    .append('foreignObject')
    .attr('height', (d: any) => (d.class === 'main-story-node' ? 65 : 55))
    .attr('width', (d: any) =>
      d.class === 'main-story-node' || d.class === 'story-node' ? 110 : 70
    )
    .attr('x', (d: any) =>
      d.class === 'main-story-node' || d.class === 'story-node' ? -55 : -35
    )
    .attr('y', (d: any) =>
      d.class === 'main-story-node' ? 60 : d.class === 'story-node' ? 20 : 12
    )
    .append('xhtml:p')
    .attr('class', (d: any) => d.class)
    .text((d: any) => d.name)

  simulation.on('tick', () => {
    link
      .attr('x1', (d: any) => d.source.x)
      .attr('y1', (d: any) => d.source.y)
      .attr('x2', (d: any) => d.target.x)
      .attr('y2', (d: any) => d.target.y)
    node
      .attr('cx', (d: any) => d.x as number)
      .attr('cy', (d: any) => d.y as number)
      .attr('transform', (d: any) => {
        return `translate(${d.x},${d.y})`
      })
  })

  function transition(zoomLevel: number) {
    svg
      .transition()
      .delay(100)
      .duration(500)
      .call(zoom.scaleBy as any, zoomLevel)
  }

  if (nodes.length >= 30) {
    transition(0.8)
  } else {
    transition(1)
  }

  d3.selectAll('.zoom-button').on('click', function () {
    // @ts-ignore
    if (this && this.id === 'zoom-in') {
      transition(1.4) // increase on 0.2 each time
    }
    // @ts-ignore
    if (this.id === 'zoom-out') {
      transition(0.6) // deacrease on 0.2 each time
    }
    // @ts-ignore
    if (this.id === 'zoom-init') {
      svg
        .transition()
        .delay(100)
        .duration(500)
        .call(zoom.scaleTo as any, 0.7) // return to initial state
    }
  })

  return {
    destroy: () => {
      simulation.stop()
    },
    nodes: () => {
      return svg.node()
    },
  }
}
