import { Button, backgroundColor, textColor } from "../../../styles"
import { TextElement } from "../TextColumn/styles"

interface AudioProps {
  assetRefParts: string[]
}

const PdfFile = ({ assetRefParts }: AudioProps) => {
  const id = assetRefParts[1] // "ff7..."
  const format = assetRefParts[2] // "m4a"
  const assetUrl = `https://cdn.sanity.io/files/${process.env.REACT_APP_SANITY_ID}/production/${id}.${format}`
  return (
    <TextElement>
      <div className="container">
        <div className="header no-margin"></div>
        <div className="text-content">
          <Button
            buttonColor="#FFC434"
            buttonTextColor={textColor}
            backgroundColor="#FFC434"
          >
            <a href={assetUrl} download>
              Download PDF
            </a>
          </Button>
        </div>
      </div>
    </TextElement>
  )
}

export default PdfFile
