import styled, { createGlobalStyle } from "styled-components"
import theme from "styled-theming"

export const backgroundColor = "#E5E7E8"

export const accentColor = "#0083C5"

export const secondColor = "#FFC434"

export const thirdColor = "#6CCAB4"

export const textColor = "#1E1E1E"

export const fadedWhite = "rgba(255, 255, 255, 0.5)"

export const secondBgColor = "#f5f5f5"

export const GlobalStyles = createGlobalStyle`

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

/* Set core body defaults */
body {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  background-color: ${accentColor};
  -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    
  &.blocked {
    overflow: hidden;
  }
}

.flex {
  display: flex !important;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

a {
    color: ${textColor};
    text-decoration: none;
    display: inline-block;
    font-weight: 800;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    * {
        color: ${textColor};
    }
  }

  p {
    margin-bottom: 16px;
    line-height: 1.4;
    font-size: 16px;
  }

  .slider-3d {
    width: 100%;
    canvas {
      width: 100vw;
      height: 100vh;
    }
  }

  @media only screen and (max-width: 1150px) {

    p {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 800px) {

  p {
  font-size: 12px;
  }
}
`

interface ButtonProps {
  buttonTextColor?: string
  buttonColor?: string
  backgroundColor?: string
  position?: string
  hoverColor?: string
  border?: number
  marginLeft?: number
}

export const Button = styled.button<ButtonProps>`
  position: ${(props) => (props.position ? props.position : "relative")};
  display: block;
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "rgba(255, 255, 255, 0.6)"};
  outline: none;
  border-radius: 5px;
  border: ${(props) => (props.border ? props.border : 1)}px solid
    ${(props) => (props.buttonColor ? props.buttonColor : "#1e1e1e")};
  /* border-width: ${(props) => (props.border ? props.border : 1)}px; */
  color: ${(props) =>
    props.backgroundColor === accentColor || props.buttonTextColor
      ? props.buttonTextColor
      : textColor};
  padding: 12px 25px;
  text-transform: lowercase;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 0)}px;
  font-weight: 600;
  p {
    margin-bottom: 0;
    color: ${(props) =>
      props.backgroundColor === accentColor || props.buttonTextColor
        ? props.buttonTextColor
        : textColor};
    text-transform: lowercase;
    font-size: 14px;
    font-weight: 600;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
    p {
      color: ${textColor} !important;
    }

    @media only screen and (max-width: 800px) {
      background-color: ${(props) => props.backgroundColor};
      p {
        color: ${(props) =>
          props.buttonTextColor ? props.buttonTextColor : textColor};
      }
    }
  }
  &.selected {
    background-color: ${textColor};
    p {
      color: ${backgroundColor} !important;
    }
  }
  &.small {
    padding: 6px 12px;

    p {
      font-size: 12px;
    }
  }

  &.x-small {
    padding: 4px 10px;

    p {
      font-size: 11px;
    }
  }

  &.nav-button {
    p {
      font-size: 14px;
    }
  }
  &.medium {
    padding: 8px 8px 8px 14px;
    p {
      font-size: 14px;
    }
  }

  &.normal {
    padding: 12px 16px 12px 16px;
    p {
      font-size: 14px;
    }
  }
  &.fake {
    pointer-events: none;
  }
  &.no-hover {
    &:hover {
      opacity: 0.8;
      background-color: ${(props) => props.backgroundColor};
      color: ${(props) => props.buttonTextColor};
    }
  }
  &.arrow {
    backdrop-filter: blur(5px);
    svg {
      path {
        transition: all 0.3s ease;
      }
    }
    &:hover {
      svg {
        path {
          stroke: ${textColor};
        }
      }
    }
  }
  &.pill {
    padding: 8px 12px 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      height: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 7px;
    }
    svg {
      height: 7px;
      path {
        stroke: ${backgroundColor};
      }
    }
    p {
      font-size: 12px;
    }

    &:hover {
      background-color: ${textColor};
      p {
        color: ${backgroundColor} !important;
      }
    }
  }
  &.zoom-button {
    svg {
      transform: rotate(45deg);
    }
  }
  &.close {
    width: 32px;
    height: 32px;
    top: 12px;
    right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 4px;
    svg {
      position: absolute;
    }
  }

  @media only screen and (max-width: 980px) {
    padding: 6px 8px;

    p {
      font-size: 11px;
    }

    a {
      font-size: 11px;
    }
  }

  @media only screen and (max-width: 800px) {
    &.arrow {
      background-color: rgba(255, 255, 255, 1);
    }

    &.nav-button {
      p {
        font-size: 12px;
      }
    }
  }
`
