import { cubicBezier } from 'framer-motion'

export const hidden = {
  opacity: 0,
}

export const cubicEase = cubicBezier(0.6, 0.01, -0.05, 0.95)

export const cubicEaseTwo = cubicBezier(1, -0.04, 0.97, 0.98)
// cubic-bezier(.07,.54,.31,.3)
export const show = {
  opacity: 1,
  transition: {
    duration: 0.2,
    ease: 'easeInOut',
  },
}

export const hide = {
  opacity: 0,
  transition: {
    duration: 0.3,
    ease: 'easeInOut',
  },
}

export const showDelay = {
  opacity: 1,
  transition: {
    duration: 0.5,
    delay: 0.5,
    ease: 'easeInOut',
  },
}

export const showDelayMore = {
  opacity: 1,
  transition: {
    duration: 0.4,
    delay: 0.8,
    ease: 'easeInOut',
  },
}

export const hideDelay = {
  opacity: 0,
  transition: {
    duration: 0.4,
    ease: 'easeInOut',
  },
}

export const hiddenDown = {
  opacity: 0,
  y: 40,
}

export const showAndRaise = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.2,
    delay: 0.5,
    ease: 'easeIn',
  },
}

export const down = {
  y: '100%',
}

export const normal = {
  y: 0,
}

export const transition = {
  duration: 1,
  delay: 0.3,
  ease: cubicEase,
}

export const out = {
  y: '-100%',
}

export const transitionDelayed = {
  duration: 1,
  delay: 1.3,
  ease: cubicEase,
}

export const noExit = {
  y: 0,
}
