import styled from 'styled-components'
import {
  accentColor,
  backgroundColor,
  secondColor,
  textColor,
  thirdColor,
} from '../../styles'
import { motion } from 'framer-motion'

interface CardProps {
  zIndex: number
}

export const Card = styled.div<CardProps>`
  position: relative;
  grid-area: 1/1;
  z-index: ${(props) => props.zIndex || 1};
  width: 340px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: none;
  border: 1px solid ${backgroundColor};
  /* border-radius: 10px; */
  overflow: hidden;

  &.clicked {
    cursor: default !important;
  }

  .image-container {
    aspect-ratio: 9/12;
    overflow: hidden !important;
    width: 100%;
    display: flex;
    align-items: stretch;
    div {
      overflow: hidden;
    }
    img,
    picture {
      width: 100%;
      object-fit: cover;
      user-select: none;
      pointer-events: none;
    }
    picture {
      display: flex;
      img {
        width: 100%;
        object-fit: cover;
        user-select: none;
        pointer-events: none;
      }
    }
  }

  @media screen and (max-width: 1150px) {
  }

  @media screen and (max-width: 800px) {
    width: 280px;
  }
`

export const Info = styled(motion.div)`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 12px;
  display: flex;
  cursor: unset;

  .info {
    position: relative;
    width: 100%;
    min-height: 120px;
    background-color: ${backgroundColor};
    padding: 12px;
    border-radius: 5px;
    border: 1px solid ${secondColor};
    .labels {
      position: absolute;
      bottom: 8px;
      left: 12px;
      width: 95%;
      display: flex;
      justify-content: flex-start;
      p {
        position: relative;
        width: auto !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 4px;
        padding-left: 10px;
        font-size: 10px;

        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        &.tag {
          &::before {
            content: '';
            position: absolute;
            left: 2px;
            top: 5px;
            display: block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: ${secondColor};
          }
        }
        &.cat {
          &::before {
            content: '';
            position: absolute;
            top: 5px;
            left: 2px;
            display: block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: ${accentColor};
          }
        }
        &.story {
          &::before {
            content: '';
            position: absolute;
            top: 4px;
            left: 2px;
            display: block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: ${thirdColor};
          }
        }
      }
    }
  }

  button {
    width: 120px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    aspect-ratio: 1;
    margin-left: 4px;
    padding: 0;
    transition: all 0.3s ease;
    svg {
      width: 80%;
      height: 24px;
      transform: rotate(-90deg);
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.7);
    }
  }

  h3 {
    font-size: 18px;
    line-height: 1.1;
    margin-bottom: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  p {
    font-size: 12px;
    width: 98%;
    line-height: 1.4;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 4px 0;
  }

  @media screen and (max-width: 800px) {
    padding: 8px;

    h3 {
      font-size: 16px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 1.2;
    }
    p {
      display: none;
    }

    .info {
      position: relative;
      padding: 8px;
      min-height: 80px;
      height: 80px;

      .labels {
        bottom: 5px;
        left: 5px;

        p {
          font-size: 8px;

          &.tag {
            &::before {
              top: 3px;
            }
          }
          &.cat {
            &::before {
              top: 3px;
            }
          }
          &.story {
            &::before {
              top: 3px;
            }
          }
        }
      }
    }

    button {
      width: 80px;

      svg {
        height: 16px;
      }
    }
  }
`
