import styled from 'styled-components'
import { RangeSlider } from '../../Range/styles'
import { RangeContainer } from '../AudioPlayer/styles'
import {
  Button,
  backgroundColor,
  secondColor,
  textColor,
} from '../../../styles'

export const StyledPlayer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  margin: 0 auto 2em auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
  border-radius: 8px;
  .ytp-watermark,
  .ytp-button {
    display: none !important;
  }

  .container {
    position: relative;
    overflow: hidden;
    aspect-ratio: 16/9;
    height: 100%;
    width: 100%;
    max-width: 1200px;
    border-radius: 8px;
    .react-player {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  .controls {
    height: auto;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    padding: 12px;
    gap: 12px;
    align-items: center;
    background-color: ${secondColor};

    input {
      appearance: none;
      -webkit-appearance: none;
      background-color: ${backgroundColor};
      border: 1px solid ${textColor};
      border-radius: 20px;
      height: 8px;
      outline: none;
      opacity: 1;
      transition: opacity 0.3s ease;
      overflow: hidden;
      cursor: pointer;

      &::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        width: 10px;
        height: 10px;
        background-color: ${textColor};
        border-radius: 20px;
        box-shadow: -2006px 0 0 2000px ${textColor};
      }
    }

    ${RangeSlider} {
      max-width: 100px;
      display: flex;
    }

    .volume {
      margin-left: 0;
    }

    p {
      margin-bottom: 0;
      font-size: 12px;

      &:last-child {
        margin-left: 6px;
      }
    }

    ${Button} {
      p {
        margin-left: 0;
      }
    }

    .progress {
      width: calc(100% - 16px);
    }
  }

  .controls-ghost {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 180px;
    background-color: transparent;
  }

  @media screen and (max-width: 1150px) {
    padding: 24px;
  }

  @media screen and (max-width: 900px) {
    padding: 16px;
    .container {
    }
  }
`
