import styled from 'styled-components'
import { backgroundColor, textColor } from '../../styles'

export const SearchBox = styled.section`
  position: relative;
  padding: 0 32px;
  margin-top: 56px;

  .line {
    position: absolute;
    top: 0%;
    left: 32px;
    width: calc(100% - 64px);
    height: 1px;
    background-color: ${textColor};

    @media screen and (max-width: 1150px) {
      left: 24px;
      width: calc(100% - 48px);
    }
    @media screen and (max-width: 800px) {
      left: 16px;
      width: calc(100% - 32px);
    }
  }

  .text {
    position: relative;
    width: 100%;
    padding: 10rem 0;
    display: flex;

    div {
      width: 50%;
      &:first-child {
        padding-right: 6rem;
      }

      p {
        margin-bottom: 2rem;
      }

      button {
        border: none;
        
      }
    }

    h3 {
      font-size: 4.4vw;
      line-height: 0.95;
      font-weight: 800;
      text-transform: uppercase;
    }
  }

  .wrapper {
    position: relative;
    width: 50%;
    padding: 16px 0 24px;
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;

    .inner {
      position: relative;
      width: 360px;
      border-bottom: 1px solid lightgray;

      .icon {
        position: absolute;
        width: 20px;
        right: 0;
        bottom: 3px;
        cursor: pointer;

        svg {
          height: 12px;
        }
      }

      .close-icon {
        bottom: 3px;

        svg {
          height: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 1150px) {
    padding: 24px 24px 0 24px;

    .text {
      width: 100%;
    }

    .wrapper {
      width: 100%;

      .inner {
        width: auto;
      }
    }
  }

  @media screen and (max-width: 980px) {
    .text {
      padding-top: 12px;
    }
    .wrapper {
      .inner {
        width: 100%;

        .icon {
          right: 0;
          cursor: pointer;

          svg {
            height: 10px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    padding: 16px 16px 0 16px;

    .text {
      flex-direction: column;
      gap: 1.5rem;
      div {
        width: 100%;

        &:first-child {
          padding-right: 0;
        }
      }

      h3 {
        font-size: 7vw;
      }
    }

    .wrapper {
      padding: 0px 0 8px;
    }
  }
`

export const InputBox = styled.input`
  position: relative;
  min-width: 360px;
  min-height: none;
  width: auto;
  height: auto;
  background-color: #e5e7e8;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 5px;
  outline: none;
  padding: 4px 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  &::-webkit-input-placeholder {
    color: ${textColor};
    opacity: 0.2;
  }

  @media screen and (max-width: 980px) {
    width: 100%;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    min-width: 200px;
  }
`
