import React, { ChangeEvent, useState } from 'react'
// import PropTypes from 'prop-types'
import { RangeContainer, RangeSlider } from './styles'

interface RangeProps {
  max: number
  min: number
  handleChange: (e: number) => void
  value: number
  isVolume: boolean
  isInVideo?: boolean
}

function Range({
  min,
  max,
  handleChange,
  value,
  isVolume,
  isInVideo,
}: RangeProps) {
  const [isHovered, setIsHovered] = useState(false)
  const percentage = ((value - min) / (max - min)) * 100

  const handleMouseOver = () => {
    setIsHovered(true)
  }

  const handleMouseOut = () => {
    setIsHovered(false)
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value)
    handleChange(parseFloat((e.target as HTMLInputElement)?.value))
  }

  return (
    <RangeContainer
      className={
        isInVideo && isVolume
          ? 'volume in-video'
          : isVolume
          ? 'volume audio-track'
          : 'audio-track'
      }
    >
      <RangeSlider
        max={max}
        min={min}
        onChange={handleInputChange}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        step="0.01"
        // style={inlineStyle}
        type="range"
        value={value}
      />
      {isVolume && <p>vol.</p>}
    </RangeContainer>
  )
}

export default Range
