//eslint-disable
import { useEffect, useRef, useState } from 'react'
import { useCentrinnoContext } from '../../context/storyContext'
import { IContext, IImage, IPreference, IStory } from '../../types/types'
import { SliderWrapper } from './styles'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import MotionStoryCard from '../StoryCard'
import { isMobile } from '../../helpers/windowHelpers'
import { useLocation } from 'react-router-dom'
import {
  cubicEaseTwo,
  hidden,
  hide,
  hideDelay,
  showDelay,
} from '../../helpers/animationVariants'
import { shuffleStories } from '../../helpers/shuffle'
import { Button, fadedWhite, secondColor } from '../../styles'
import { ButtonRow } from '../StoryContent/Metadata/styles'

interface SliderProps {
  relatedStories: IStory[]
  selectedVariables: IPreference[]
}

const Slider = ({ relatedStories, selectedVariables }: SliderProps) => {
  const [sliderStories, setSliderStories] = useState<IStory[] | null>([])
  const [isLoading, setIsLoading] = useState(false)
  const { stories } = useCentrinnoContext() as IContext
  const [clickedStory, setClickedStory] = useState<number | null>(null)
  const [centerPoint, setCenterPoint] = useState(window.innerWidth / 2)
  const [distance, setDistance] = useState(0)
  const location = useLocation()

  useEffect(() => {
    stories && setSliderStories(stories)
    if (selectedVariables.length === 0) {
      setIsLoading(true)
      setClickedStory(null)
      setSliderStories(null)
      let timer2 = setTimeout(() => {
        setSliderStories(shuffleStories(stories).slice(0, 80))
      }, 1000)
      let timer3 = setTimeout(() => {
        setIsLoading(false)
      }, 1500)

      return () => {
        clearTimeout(timer2)
        clearTimeout(timer3)
      }
    } else if (selectedVariables && selectedVariables.length >= 1) {
      setIsLoading(true)
      setClickedStory(null)
      setSliderStories(null)
      let timer = setTimeout(() => {
        setSliderStories(shuffleStories(relatedStories.slice(0, 30)))
      }, 1000)
      let timer2 = setTimeout(() => {
        setIsLoading(false)
      }, 1500)

      return () => {
        clearTimeout(timer)
        clearTimeout(timer2)
      }
    } else {
      setClickedStory(null)
      setSliderStories(null)
    }
  }, [stories, selectedVariables, relatedStories])

  const ref = React.createRef<HTMLDivElement>()

  const containerRef = useRef<HTMLDivElement>(null)
  const scrollWidth = containerRef?.current?.scrollWidth

  const isInteractive = scrollWidth && scrollWidth > window.innerWidth

  // console.log(centerPoint)

  const raiseMob = {
    opacity: 1,
    width: 340,
    x: centerPoint + distance - 170,
    y: -20,
    rotateY: 0,
    rotateZ: 0,
    zIndex: 333,
    transition: {
      duration: 0.4,
      ease: cubicEaseTwo,
    },
  }

  const raise = {
    opacity: 1,
    width: 500,
    y: -100,
    x: centerPoint + distance - 250,
    rotateY: 0,
    rotateZ: 0,
    zIndex: 333,
    transition: {
      duration: 0.4,
      ease: cubicEaseTwo,
    },
  }

  const handleClick = (index: number) => {
    if (clickedStory === null) {
      setClickedStory(index)
    } else {
    }
  }

  const handleReset = () => {
    setClickedStory(null)
  }

  useEffect(() => {
    if (location.pathname.includes('story')) {
      setTimeout(() => {
        handleReset()
      }, 1100)
    }
  }, [location])

  const handleRight = () => {
    if (containerRef && containerRef.current) {
      containerRef.current.scrollBy({
        left: 100,
        behavior: 'smooth',
      })
    }
  }

  const handleLeft = () => {
    if (containerRef && containerRef.current) {
      containerRef.current.scrollBy({
        left: -100,
        behavior: 'smooth',
      })
    }
  }

  const handleScrollSides = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = event.target as HTMLElement
    const newDistance = target.scrollLeft

    setDistance(newDistance)
  }

  return (
    <SliderWrapper>
      {isInteractive && (
        <ButtonRow>
          <Button
            backgroundColor={fadedWhite}
            buttonColor={secondColor}
            className="arrow"
            onClick={handleLeft}
          >
            <svg
              width="23"
              height="15"
              viewBox="0 0 23 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <motion.path
                d="M21.503 1.9772L11.492 11.9882L1.48095 1.9772"
                stroke={secondColor}
                strokeWidth="2"
              />
            </svg>
          </Button>
          <Button
            backgroundColor={fadedWhite}
            buttonColor={secondColor}
            className="arrow"
            onClick={handleRight}
          >
            <svg
              width="23"
              height="15"
              viewBox="0 0 23 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <motion.path
                d="M21.503 1.9772L11.492 11.9882L1.48095 1.9772"
                stroke={secondColor}
                strokeWidth="2"
              />
            </svg>
          </Button>
        </ButtonRow>
      )}

      <AnimatePresence>
        {sliderStories && sliderStories.length === 0 && (
          <motion.p
            initial={hidden}
            animate={showDelay}
            exit={hide}
            className="empty"
            key="error"
          >
            There are no stories that have&nbsp;
            <AnimatePresence>
              {selectedVariables &&
                selectedVariables.length > 0 &&
                selectedVariables.map(
                  (variable: IPreference, index: number) => (
                    <motion.span
                      initial={hidden}
                      animate={showDelay}
                      exit={hideDelay}
                      key={index}
                    >
                      {variable.title.toLowerCase()},&nbsp;
                    </motion.span>
                  )
                )}
            </AnimatePresence>
            in common.
          </motion.p>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isLoading && (
          <motion.div
            initial={hidden}
            animate={showDelay}
            exit={hide}
            className="story-loader"
            key="loader"
          >
            <p>Loading stories...</p>
          </motion.div>
        )}
      </AnimatePresence>

      <motion.div
        ref={containerRef}
        className="container"
        onScroll={(event) => handleScrollSides(event)}
      >
        {clickedStory !== null && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 0.5,
              transition: {
                duration: 0.5,
                delay: 0.2,
                ease: cubicEaseTwo,
              },
            }}
            className="overlay"
            onClick={handleReset}
          />
        )}
        <AnimatePresence>
          {sliderStories &&
            sliderStories?.length > 0 &&
            sliderStories?.map((story: IStory, index) => (
              <MotionStoryCard
                key={index}
                initial={{
                  opacity: 0,
                  width: isMobile ? 280 : 340,
                  x: index * (isMobile ? 32 : 20),
                  rotateY: -60,
                  rotateZ: 15,
                  y: 50,
                }}
                whileHover={{
                  y:
                    clickedStory === index
                      ? isMobile
                        ? -20
                        : -100
                      : isMobile
                      ? 0
                      : -30,
                }}
                animate={
                  clickedStory === index
                    ? isMobile
                      ? raiseMob
                      : raise
                    : {
                        opacity: 0.8,
                        y: 0,
                        x: index * (isMobile ? 32 : 20),
                        boxShadow: '4px 4px 16px 1px rgba(30, 30, 30, 0.4)',
                        transition: {
                          duration: 0.5,
                          ease: cubicEaseTwo,
                        },
                      }
                }
                ref={ref}
                image={story?.heroImage as IImage}
                title={story?.title}
                summary={story?.summaryText}
                zIndex={stories.length - index + 1}
                clickFunction={() => handleClick(index)}
                visible={clickedStory === index}
                tags={story?.tags}
                className={`${clickedStory === index ? 'clicked' : ''} `}
                isClicked={clickedStory === index}
                onClose={() => handleReset()}
              />
            ))}
        </AnimatePresence>
      </motion.div>
    </SliderWrapper>
  )
}
export default Slider
