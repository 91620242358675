import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { slugify } from '../../helpers/slugify'
import {
  Button,
  backgroundColor,
  fadedWhite,
  secondColor,
  textColor,
} from '../../styles'
import { IImage } from '../../types/types'
import ImgHandler from '../ImgHandler'
import { Card, Info } from './styles'
import { AnimatePresence, motion } from 'framer-motion'
import { hiddenDown, hide, showAndRaise } from '../../helpers/animationVariants'
import { IPreference } from '../../types/types'

interface StoryCardProps {
  image: IImage
  title: string
  summary: string
  zIndex: number
  clickFunction: () => void
  onClose: () => void
  visible?: boolean
  tags?: IPreference[]
  className?: string
  isClicked?: boolean
}

const StoryCard = React.forwardRef<HTMLDivElement, StoryCardProps>(
  (
    {
      image,
      title,
      summary,
      zIndex,
      clickFunction,
      visible,
      tags,
      className,
      isClicked,
      onClose,
    },
    ref
  ) => {
    return (
      <Card
        ref={ref}
        key={title.toLowerCase()}
        zIndex={zIndex}
        onClick={() => clickFunction()}
        className={className}
      >
        {isClicked && (
          <Button
            className="close"
            position="absolute"
            onClick={() => onClose()}
            backgroundColor={backgroundColor}
            buttonColor={secondColor}
          >
            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.983398 14.6211L14.1768 1.42771"
                stroke={textColor}
                strokeWidth="2"
              />
              <path
                d="M0.983398 1.42725L14.1768 14.6206"
                stroke={textColor}
                strokeWidth="2"
              />
            </svg>
          </Button>
        )}
        {image && (
          <ImgHandler imgSrc={image} altText={image?.title} width={1000} />
        )}

        <AnimatePresence>
          {visible && (
            <Info
              initial={hiddenDown}
              animate={showAndRaise}
              exit={hide}
              className="story-info"
              key="story-info"
            >
              <div className="info">
                <h3>{title}</h3>
                <p>{summary}</p>
                <div className="labels">
                  <p className="tag">
                    {tags && tags?.length === 1
                      ? tags[0].title.toLowerCase()
                      : tags
                          ?.slice(0, 2)
                          .map((tag: IPreference, index: number) =>
                            index === 0
                              ? tag.title.toLowerCase() + ', '
                              : tag.title.toLowerCase()
                          )}
                  </p>
                  <p className="cat">
                    {tags && tags?.length === 1
                      ? tags[0].category?.title.toLowerCase()
                      : tags
                          ?.slice(0, 2)
                          .map((tag: IPreference, index: number) =>
                            index === 0
                              ? tag.category?.title.toLowerCase() + ', '
                              : tag.category?.title.toLowerCase()
                          )}
                  </p>
                </div>
              </div>
              <Link to={`/story/${slugify(title)}`}>
                <Button
                  backgroundColor={fadedWhite}
                  buttonColor={secondColor}
                  className="arrow"
                >
                  <svg
                    width="23"
                    height="15"
                    viewBox="0 0 23 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <motion.path
                      d="M21.503 1.9772L11.492 11.9882L1.48095 1.9772"
                      stroke={secondColor}
                      strokeWidth="2"
                    />
                  </svg>
                </Button>
              </Link>
            </Info>
          )}
        </AnimatePresence>
      </Card>
    )
  }
)

const MotionStoryCard = motion(StoryCard)

export default MotionStoryCard
