import imageUrlBuilder from "@sanity/image-url"
import { IStory, IImage, IPreference, INode, ILink } from "../types/types"
import client from "../sanityService"

export const jsonFyStory = (selectedVariable: IStory, stories: IStory[]) => {
  const builder = imageUrlBuilder(client)

  function urlFor(source: IImage) {
    return builder.image(source)
  }

  const categoryNodes = selectedVariable.tags
    .map((tag) => tag?.category)
    .filter(
      (value, index, self) =>
        index === self.findIndex((t) => t?.title === value?.title)
    )
    .map((cat, index) => {
      return {
        id: cat?.title,
        name: cat?.title,
        class: "category-node",
        definition: cat?.definition,
      }
    })
  let tagNodes: INode[] = []
  let secondLevelLinks: ILink[] = []
  let thirdLevelLinks: ILink[] = []
  selectedVariable.tags.forEach((tag: IPreference) => {
    tagNodes.push({
      id: tag.title,
      name: tag.title,
      class: "tag-node",
      definition: tag?.definition,
    })
    secondLevelLinks.push({
      source: tag.category?.title,
      target: tag.title,
    })
  })

  let connectedStoryNodes: INode[] = []
  stories.forEach((story: IStory) => {
    story.tags.forEach((tag: IPreference) => {
      selectedVariable.tags.forEach((selectedTag: IPreference) => {
        if (
          selectedTag.title === tag.title &&
          story.title !== selectedVariable.title
        ) {
          connectedStoryNodes.push({
            id: story.title,
            name: story.title,
            class: "story-node",
            tags: story.tags,
            summary: story.summaryText,
            image: story.heroImage && urlFor(story.heroImage).width(200).format('webp').url(),
          })
          thirdLevelLinks.push({
            source: tag.title,
            target: story.title,
          })
        }
      })
    })
  })
  connectedStoryNodes = connectedStoryNodes.filter(
    (value: INode, index: number, self: INode[]) =>
      index === self.findIndex((t: INode) => t?.name === value?.name)
  )

  let firstLevelLinks: ILink[] = []
  selectedVariable.tags.forEach((tag) => {
    firstLevelLinks.push({
      source: "main-story-node",
      target: tag?.category?.title,
    })
  })

  firstLevelLinks = firstLevelLinks.filter(
    (value: ILink, index: number, self: ILink[]) =>
      index === self.findIndex((t: ILink) => t?.target === value?.target)
  )

  const storyNode = {
    id: "main-story-node",
    name: selectedVariable.title,
    class: "main-story-node",
    definition: selectedVariable.definition,
    summary: selectedVariable.summaryText,
    // children: childrenNodes,
    image:
      selectedVariable.heroImage &&
      urlFor(selectedVariable.heroImage).width(150).url(),
  }

  const storyData = {
    nodes: [storyNode, ...categoryNodes, ...tagNodes, ...connectedStoryNodes],
    links: [...firstLevelLinks, ...secondLevelLinks, ...thirdLevelLinks],
  }

  return storyData
}
