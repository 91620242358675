export const slugify = (string: string) => {
  const regex = /[,: ]\s+/g
  const newString = string?.replace(regex, '-').trim()
  const optimizedString = newString
    ?.replace(/--/g, '-')
    .toLowerCase()
    .replace(/\s/g, '-')

  return optimizedString
}
