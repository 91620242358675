import { motion } from 'framer-motion'
import styled from 'styled-components'
import { ButtonRow } from '../../StoryContent/Metadata/styles'
import { Button } from '../../../styles'

export const GallerySection = styled(motion.div)`
  overflow: hidden;
  padding: 32px;

  .wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    user-select: none;

    cursor: grab;
  }

  ${ButtonRow} {
    margin-top: 16px;
    ${Button} {
      padding: 16px;

      &:first-child {
        transform: rotate(90deg);

        svg {
          margin-bottom: -4px;
        }
      }

      &:last-child {
        transform: rotate(-90deg);

        svg {
          margin-top: -4px;
        }
      }
    }
  }

  @media only screen and (max-width: 1150px) {
    padding: 24px;
  }

  @media only screen and (max-width: 800px) {
    padding: 16px;
  }
`

export const GalleryImage = styled(motion.div)`
  flex: none;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  img {
    height: 60vh;
    width: auto;
    object-fit: cover;
    pointer-events: none;
    cursor: grab;
  }

  @media only screen and (max-width: 800px) {
    img {
      height: 50vh;
    }
  }
`
