import React from 'react'
import PropTypes from 'prop-types'

import convertTime from '../../helpers/convertTime'
import Range from '../Range'
import { AudioLabel } from './styles'

interface SeekerProps {
  currentTime: number
  duration: number
  handleTrackClick: (position: number) => void
}

function Seeker({ currentTime, duration, handleTrackClick }: SeekerProps) {
  return (
    <>
      <AudioLabel>{convertTime(currentTime)}</AudioLabel>
      <Range
        max={duration}
        value={currentTime}
        handleChange={handleTrackClick}
        min={0}
        isVolume={false}
      />
      <AudioLabel>{convertTime(duration)}</AudioLabel>
    </>
  )
}

// Seeker.propTypes = {
//   currentTime: PropTypes.number.isRequired,
//   duration: PropTypes.number.isRequired,
//   handleTrackClick: PropTypes.func.isRequired,
// }

export default Seeker
