import styled from "styled-components"
import { accentColor, secondColor, textColor } from "../../../styles"

export const TextElement = styled.div`
  position: relative;
  padding: 32px 32px 0;
  margin: 0 auto;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .header {
      width: 35%;
      padding-right: 40px;

      &.no-margin {
        margin: 0;
      }

      h3 {
        font-size: 56px;
        line-height: 1.1;
      }
    }

    .text-content {
      width: 65%;

      a {
        text-transform: none;
        font-size: 18px;
        font-weight: 600;
        text-decoration: underline;
        transition: color 0.3s ease;
        &:hover {
          color: ${accentColor};
        }
      }
      button {
        a {
          text-decoration: none;
          text-transform: uppercase;
          &:hover {
            color: ${textColor};
          }
        }
      }
      p {
        font-size: 18px;
      }
    }
  }

  &.text-left {
    .container {
      /* flex-direction: row-reverse; */

      /* h3 {
        padding-right: 0;
        padding-left: 40px;
      } */
    }
  }

  @media only screen and (max-width: 1500px) {
    .container {
      .header {
        width: 45%;
      }

      .text-content {
        width: 55%;

        a {
          font-size: 16px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }

  @media only screen and (max-width: 1260px) {
    .container {
      .header {
        width: 50%;

        h3 {
          font-size: 40px;
        }
      }

      .text-content {
        width: 50%;

        p,
        a {
          font-size: 14px;
        }
      }
    }
  }

  @media only screen and (max-width: 1150px) {
    padding: 24px 24px 0px;

    .container {
      flex-wrap: wrap;
      justify-content: flex-end;

      .header {
        width: 100%;
        margin-bottom: 24px;

        h3 {
          font-size: 32px;
          padding-right: 0;
          margin-bottom: 0;
        }
      }

      .text-content {
        width: 88%;
      }
    }

    &.text-left {
      .container {
        flex-direction: row;

        h3 {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }

  @media only screen and (max-width: 980px) {
    padding: 24px 24px 0px;
    flex-wrap: wrap;
    h3 {
      font-size: 32px;
      line-height: 1.1;
      width: 100%;
      padding-right: 0;
      margin-bottom: 24px;
    }

    .container {
      .header {
        margin-bottom: 16px;

        h3 {
          font-size: 24px;
          padding-right: 0;
          margin-bottom: 0;
        }
      }

      .text-content {
        width: 100%;

        p,
        a {
          font-size: 14px;
        }
      }
    }
  }

  @media only screen and (max-width: 800px) {
    padding: 16px 16px 0px;
  }
`
