import { useEffect, useState } from 'react'
import { Button, accentColor, secondColor, textColor } from '../../../styles'
import { IPreference } from '../../../types/types'
import { ButtonRow, MetaDataSection, Row } from './styles'
import { AnimatePresence, motion } from 'framer-motion'
import {
  hidden,
  hide,
  hideDelay,
  show,
  showAndRaise,
  showDelay,
} from '../../../helpers/animationVariants'

interface MetaDataProps {
  title: string
  subject: string
  location: string
  method: string
  date: string
  affectedLocations: string
  tags: IPreference[]
}

const MetaData = ({
  tags,
  subject,
  location,
  method,
  date,
  affectedLocations,
  title,
}: MetaDataProps) => {
  const [categories, setCategories] = useState<string[]>()
  const [link, setLink] = useState('')
  const [isCopied, setIsCopied] = useState(false)

  useEffect(() => {
    const link = window && window.location.href
    setLink(link)
  }, [])

  useEffect(() => {
    const tagCats =
      tags &&
      tags.length > 1 &&
      tags
        .slice(0, 8)
        .map((tag: IPreference, index: number) => tag?.category?.title)

    setCategories(tagCats as string[])
  }, [tags])

  const handleClick = () => {
    navigator.clipboard.writeText(link)
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 3000)
  }

  return (
    <MetaDataSection>
      <div className="left-half">
        <h2>{title}</h2>
        <ButtonRow>
          {tags &&
            tags.map((tag: IPreference, index: number) => (
              <Button
                key={index}
                buttonTextColor={textColor}
                buttonColor={secondColor}
                backgroundColor={secondColor}
                className="small fake"
              >
                <p>{tag.title}</p>
              </Button>
            ))}
        </ButtonRow>
        <div className="share">
          <Button
            className="copy-button"
            buttonColor={accentColor}
            onClick={handleClick}
          >
            <div className="square" />
            <div className="square" />
          </Button>
          <AnimatePresence mode="wait">
            {isCopied ? (
              <motion.p
                initial={hidden}
                animate={show}
                exit={hide}
                key="copy-cta"
              >
                Link copied to clibpoard.
              </motion.p>
            ) : (
              <motion.p
                initial={hidden}
                animate={showAndRaise}
                exit={hide}
                key="copied-cta"
              >
                Share the story.
              </motion.p>
            )}
          </AnimatePresence>
          {/* <AnimatePresence>
            {isCopied && (
              <motion.p initial={hidden} animate={showAndRaise} exit={hide}>
                Link copied to clibpoard.
              </motion.p>
            )}
          </AnimatePresence> */}
        </div>
      </div>
      <div className="right-half">
        <Row>
          <h4>
            <span>Subject:</span>
            {subject}
          </h4>
        </Row>
        <Row>
          <h4>
            <span>Collected in:</span>
            {location}
          </h4>
        </Row>
        <Row>
          <h4>
            <span>Using:</span>
            {method}
          </h4>
        </Row>
        <Row>
          <h4>
            <span>Date of Events:</span>
            {date}
          </h4>
        </Row>
        <Row>
          <h4>
            <span>Related Locations:</span>
            {affectedLocations}
          </h4>
        </Row>
      </div>
    </MetaDataSection>
  )
}

export default MetaData
