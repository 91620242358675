import styled from 'styled-components'
import { ResultsSection } from '../Results/styles'
import { accentColor, backgroundColor } from '../../styles'
import { InputBox, SearchBox } from '../Search/styles'
import { ButtonRow } from '../StoryContent/Metadata/styles'
import MagnifierIcon from '../Search/MagnifierIcon'

export const IndexWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 800px) {
    display: block;
  }
`

export const SideBar = styled.aside`
  position: sticky;
  top: 0;
  left: 0;
  min-width: 367px;
  width: 367px;
  height: 100vh;
  padding: 80px 32px 0;
  background-color: ${accentColor};

  .right-border {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background-color: ${backgroundColor};
  }

  ${SearchBox} {
    padding: 0;
    margin-top: 0;
    .wrapper {
      width: 100%;
      .inner {
        width: 100%;
      }

      ${InputBox} {
        min-width: 0;
        width: 100%;
        background-color: transparent;
        color: ${backgroundColor};
        &::-webkit-input-placeholder {
          color: ${backgroundColor};
          opacity: 0.7;
        }
      }

      svg {
        circle,
        path {
          stroke: ${backgroundColor};
        }
      }
    }
  }

  ${ButtonRow} {
    margin-bottom: 12px;
  }

  ${ResultsSection} {
    mask-image: none;
    mask-size: 0;
    overflow: hidden;
    margin-bottom: 8px;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    min-width: none;
    height: auto;
    padding: 72px 16px 8px;
    z-index: 3;

    ${ButtonRow} {
      margin-top: 12px;
      margin-bottom: 0;
    }

    ${ResultsSection} {
      flex-wrap: nowrap;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        width: 1px;
        padding: 0;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
        border-left: 0;
      }
      &::-webkit-scrollbar-thumb {
        width: 0;
        height: 0;
        border-left: 0;
        border-top: 0;
        border-bottom: 0;
      }
    }
  }
`

export const IndexContent = styled.section`
  position: relative;
  width: auto;
  width: 100%;
  background-color: ${backgroundColor};
`
