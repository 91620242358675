import { useCentrinnoContext } from "../context/storyContext"
import { IContext, IStory, IPreference, IResult } from "../types/types"

export const getRelatedStories = (item: string, stories: IStory[]) => {
  let relatedStories: IStory[] = []

  stories.forEach((story) =>
    story.tags.forEach((tag) => {
      ;(tag?.title?.toLowerCase() === item?.toLocaleLowerCase() ||
        (tag?.category?.title?.toLocaleLowerCase() as string) ===
          item?.toLocaleLowerCase()) &&
        relatedStories.push(story)
    })
  )
  relatedStories = relatedStories.filter(
    (value: IStory, index, self) =>
      index === self.findIndex((t: IStory) => t._id === value._id)
  )
  return relatedStories
}

export const getConnectedStories = (
  items: IPreference[],
  stories: IStory[]
) => {
  let connectedStories: IStory[] = []

  stories.forEach((story: IStory, index: number) => {
    const storyCats = story?.tags?.map(
      (tag: IPreference) => tag?.category?.title
    )
    const storyTags = story.tags.map((tag) => tag.title)
    const itemTitles = items.map((item) => item.title)
    itemTitles.every(
      (title) => storyCats.includes(title) || storyTags.includes(title)
    ) && connectedStories.push(story)
  })

  connectedStories = connectedStories.filter(
    (value: IStory, index, self) =>
      index === self.findIndex((t: IStory) => t._id === value._id)
  )

  return connectedStories
}

export const useSelectedVariable = (item: string) => {
  const { tags, categories } = useCentrinnoContext() as IContext

  const selectedTag = tags.find(
    (tag: IPreference) =>
      tag.title?.toLocaleLowerCase() === item?.toLocaleLowerCase()
  )

  const linkedTags = categories.find(
    (cat: IPreference) =>
      (cat.title.toLocaleLowerCase() as string) === item?.toLocaleLowerCase()
  )

  return selectedTag || linkedTags
}

export const useSelectedVariables = (items: IResult[]) => {
  const { tags, categories } = useCentrinnoContext() as IContext

  let variables: IPreference[] = []

  items.map((item: IResult) =>
    item.isTag
      ? tags.find(
          (tag: IPreference) => tag.title === item.result && variables.push(tag)
        )
      : categories.find(
          (cat: IPreference) => cat.title === item.result && variables.push(cat)
        )
  )

  return variables
}
