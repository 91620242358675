import { useState, ChangeEvent, FormEvent } from 'react'
import { motion } from 'framer-motion'
import { FormField, StyledForm } from './styles'
import { hidden, hide, show } from '../../helpers/animationVariants'
import { Button, secondColor, textColor } from '../../styles'
import { useCentrinnoContext } from '../../context/storyContext'
import { IContext } from '../../types/types'

const encode = (data: Record<string, any>) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Form = () => {
  const [isSuccess, setIsSuccess] = useState(false)

  const { contribute } = useCentrinnoContext() as IContext

  const [formState, setFormState] = useState({
    name: '',
    organization: '',
    email: '',
    findReason: '',
    storyTitle: '',
    storySummary: '',
    boldElementsJustification: '',
    storyRelevance: '',
    storyExtra: '',
  })

  const handleName = (e: ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, name: e.target.value })

    console.log(formState)
  }

  const handleOrg = (e: ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, organization: e.target.value })
  }

  const handleEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, email: e.target.value })
  }

  const handleFindReason = (e: ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, findReason: e.target.value })
  }

  const handleStoryTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, storyTitle: e.target.value })
  }

  const handleSummary = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setFormState({ ...formState, storySummary: e.target.value })
  }

  const handleJustification = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setFormState({ ...formState, boldElementsJustification: e.target.value })
  }

  const handleRelevance = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setFormState({ ...formState, storyRelevance: e.target.value })
  }

  const handleExtra = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setFormState({ ...formState, storyExtra: e.target.value })
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'submitForm', ...formState }),
    })
      .then(() => setIsSuccess(true))
      .catch((error) => console.log(error))
    e.preventDefault()
  }

  return (
    <StyledForm
      className="submitForm"
      onSubmit={handleSubmit}
      autoComplete="on"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      {!isSuccess ? (
        <div className="container">
          <p> {contribute?.subtitleDetails}</p>
          <FormField className="flex">
            <div>
              <label htmlFor="name">{contribute?.namePlaceholder}</label>

              <input
                type="text"
                name="name"
                value={formState.name}
                placeholder="Your name"
                autoComplete="on"
                onChange={handleName}
                required
              />
            </div>
            <div>
              <label htmlFor="company">
                {contribute?.organiztionPlaceholder}
              </label>
              <input
                type="text"
                name="company"
                required
                value={formState.organization}
                placeholder="Your Organization"
                autoComplete="on"
                onChange={handleOrg}
              />
            </div>
          </FormField>

          <input type="hidden" name="Submit Form" value="Story submission" />

          <FormField>
            <label htmlFor="email">{contribute?.emailPlaceholder}</label>
            <input
              type="email"
              name="email"
              autoComplete="on"
              value={formState.email}
              required
              placeholder="Your email"
              onChange={handleEmail}
            />
          </FormField>

          <FormField>
            <label htmlFor="findingReason">
              {contribute?.howDidYouHearPlaceholder}
            </label>
            <input
              type="text"
              name="findingReason"
              autoComplete="on"
              value={formState.findReason}
              placeholder={contribute?.howDidYouHearPlaceholder}
              onChange={handleFindReason}
            />
          </FormField>

          <p> {contribute?.storySubtitle}</p>

          <FormField>
            <label htmlFor="findingReason">{contribute?.storyTitle}</label>
            <input
              type="text"
              name="storyTitle"
              autoComplete="on"
              value={formState.storyTitle}
              required
              placeholder={contribute?.storyTitle}
              onChange={handleStoryTitle}
            />
          </FormField>

          <FormField>
            <label htmlFor="storySummary">{contribute?.storySummary}</label>

            <textarea
              name="storySummary"
              placeholder="Short description of your story"
              value={formState.storySummary}
              onChange={handleSummary}
            ></textarea>
          </FormField>

          <FormField>
            <label htmlFor="boldElementsJustification">
              {contribute?.storyOneSentence}
            </label>
            <textarea
              name="boldElementsJustification"
              placeholder="workplace, makerspace, community,"
              value={formState.boldElementsJustification}
              onChange={handleJustification}
            ></textarea>
          </FormField>

          <FormField>
            <label htmlFor="boldElementsJustification">
              {contribute?.storyRelevance}
            </label>
            <textarea
              name="storyRelevance"
              placeholder="This story is relevant to our Fab City Hub initiative because..."
              value={formState.storyRelevance}
              onChange={handleRelevance}
            ></textarea>
          </FormField>

          <FormField>
            <label htmlFor="boldElementsJustification">
              {contribute?.storyMedia}
            </label>
            <textarea
              name="storyExtra"
              placeholder="Anything else you would like to add"
              value={formState.storyExtra}
              onChange={handleExtra}
            ></textarea>
          </FormField>

          <Button
            type="submit"
            backgroundColor={secondColor}
            buttonColor={secondColor}
            buttonTextColor={textColor}
            color={textColor}
            className="normal "
          >
            <p>Submit</p>
          </Button>
        </div>
      ) : (
        <motion.div
          className="success"
          initial={hidden}
          animate={isSuccess ? show : hide}
        >
          <h2>Thank you</h2>
          <p>You can expect an email from us soon.</p>
        </motion.div>
      )}
    </StyledForm>
  )
}

export default Form
