import styled from 'styled-components'

export const AudioLabel = styled.span`
  min-width: 24px;
  margin: 0 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;

  @media only screen and (max-width: 800px) {
    font-size: 8px;
    min-width: 14px;
    height: 100%;
    margin-top: 2px;
  }
`
