import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import Range from '../../Range'
import { StyledPlayer } from './styles'
import { Button, backgroundColor, textColor } from '../../../styles'
import { RangeContainer } from '../AudioPlayer/styles'
import { motion } from 'framer-motion'
import { isMobile } from '../../../helpers/windowHelpers'
import Seeker from '../../Seeker'

interface VideoProps {
  videoUrl?: string
  videoRefParts?: string[]
}

const VideoPlayer = ({ videoUrl, videoRefParts }: VideoProps) => {
  const videoPlayer = useRef<ReactPlayer>(null)
  const [url, setUrl] = useState<string | null>(null)
  const [pip, setPip] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [volume, setVolume] = useState(0)
  const [isVisible, setIsVisible] = useState(false)
  const [played, setPlayed] = useState(0)
  const [duration, setDuration] = useState(0)
  const [seeking, setSeeking] = useState(false)

  const handleTrackClick = (position: number) => {
    setPlayed(position)
  }

  const handleDuration = (duration: number) => {
    setDuration(duration)
  }

  const handleSeekChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPlayed(parseFloat(e.target.value))
  }

  const handleSeekMouseDown = () => {
    setSeeking(true)
  }

  const handleSeekMouseUp = (e: React.MouseEvent<HTMLInputElement>) => {
    setSeeking(false)
    console.log(e)
    videoPlayer.current?.seekTo(
      parseFloat((e.target as HTMLInputElement).value)
    )
    if (!playing) {
      setPlaying(true)
    }
  }

  const videoId = videoRefParts && videoRefParts[1] // "ff7..."
  const videoFormat = videoRefParts && videoRefParts[2] // "m4a"
  const videoCustomUrl = `https://cdn.sanity.io/files/${process.env.REACT_APP_SANITY_ID}/production/${videoId}.${videoFormat}`

  const variants = {
    initial: {
      y: isMobile ? 0 : 100,
      transition: {
        duration: 0.5,
        type: 'easeInOut',
      },
    },
    animate: {
      y: 0,
      transition: {
        duration: 0.5,
        type: 'easeInOut',
      },
    },
  }

  return (
    <StyledPlayer className="video-yt-component">
      <div className="container">
        <ReactPlayer
          ref={videoPlayer}
          url={videoUrl || videoCustomUrl}
          config={{
            youtube: {
              playerVars: { modestbranding: 0 },
            },
          }}
          width="100%"
          height="100%"
          className="react-player"
          controls={false}
          played={played}
          playing={playing}
          volume={volume}
          onDuration={handleDuration}
        />

        <motion.div
          onMouseEnter={() => setIsVisible(true)}
          onMouseLeave={() => setIsVisible(false)}
          className="controls-ghost"
        >
          <motion.div
            initial="initial"
            animate={isMobile ? 'initial' : isVisible ? 'animate' : 'initial'}
            variants={variants}
            className="controls"
          >
            <Button
              onClick={() => setPlaying(!playing)}
              buttonColor={textColor}
              buttonTextColor={backgroundColor}
              backgroundColor={textColor}
              className="small"
            >
              <p>{playing ? 'Pause' : 'Play'}</p>
            </Button>
            <Range
              min={0}
              max={1}
              value={volume}
              handleChange={setVolume}
              isVolume={true}
              isInVideo={true}
            />
            <input
              type="range"
              min={0}
              max={0.999999}
              step="any"
              value={played}
              onChange={handleSeekChange}
              onMouseDown={handleSeekMouseDown}
              onMouseUp={handleSeekMouseUp}
              className="progress"
            />
          </motion.div>
        </motion.div>
      </div>
    </StyledPlayer>
  )
}

export default VideoPlayer
