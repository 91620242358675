import { motion } from 'framer-motion'
import styled from 'styled-components'
import { ButtonRow } from '../StoryContent/Metadata/styles'
import { Button, backgroundColor, textColor } from '../../styles'

export const IndexItem = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: stretch;
  gap: 16px;
  border-top: 1px solid #ffffff;

  &:first-child {
    border-top: 0;
  }
  .image-square {
    aspect-ratio: 1;
    width: auto;
    height: 100%;
    .image-container {
      aspect-ratio: 1;
      picture {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .content {
    position: relative;
    padding: 24px 16px;
    width: 100%;
    height: auto;

    h5 {
      margin: 0;
      margin-bottom: 12px;
    }

    p.summary {
      display: -webkit-box;

      margin-top: 12px;
      font-size: 14px;
      width: 90%;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    ${ButtonRow} {
      width: 80%;

      .csv-button {
        &:hover {
          p,
          a {
            color: ${backgroundColor}!important;
          }
        }
      }
    }

    .arrow-container {
      position: absolute;
      bottom: 16px;
      right: 16px;

      ${Button} {
        width: 64px;
        height: 64px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          transform: rotate(-90deg);
        }
      }
    }
  }

  @media screen and (max-width: 1150px) {
    flex-direction: column;
    gap: 0;

    .image-square {
      aspect-ratio: 16/9;
      width: auto;
      height: 100%;
      .image-container {
        aspect-ratio: 16/9;
      }
    }

    .content {
      padding: 16px 16px 24px;

      p.summary {
        width: 100%;
      }

      .arrow-container {
        bottom: 24px;
      }

      ${ButtonRow} {
        width: 90%;
      }
    }

    @media screen and (max-width: 800px) {
      .image-square {
        aspect-ratio: 1;
        .image-container {
          aspect-ratio: 1;
        }
      }
    }

    @media screen and (max-width: 400px) {
      .content {
        .arrow-container {
          ${Button} {
            width: 40px;
            height: 40px;
            svg {
              transform: rotate(-90deg);
              height: 12px;
            }
          }
        }
      }
    }
  }
`
