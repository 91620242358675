import styled from 'styled-components'
import { motion } from 'framer-motion'
import { accentColor } from '../../../styles'

export const HeroGroup = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  /* margin-top: ; */
  &.next-hero {
    /* margin-top: -1px; */
  }

  .progress-bar {
    height: 10px;
    background-color: ${accentColor};
  }

  .half {
    width: 50%;
    height: 100%;
    display: flex;
    overflow: hidden;
    &:first-child {
      align-items: flex-end;
      padding: 32px;
    }
    .image-container {
      width: 100%;
      height: 100%;
      picture {
        display: flex;
      }
      img, picture {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    h1 {
      font-size: 4.4vw;
      line-height: 1;
      font-weight: 800;
      text-transform: uppercase;
    }
  }
  a {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 1150px) {
    .half {
      &:first-child {
        align-items: flex-end;
        padding: 24px;
      }
    }
  }

  @media screen and (max-width: 1050px) {
    flex-wrap: wrap;

    .half {
      width: 100%;
      height: 50%;

      h1 {
        font-size: 9vw;
      }
    }
  }

  @media screen and (max-width: 800px) {
    height: 100vh;
    .half {
      &:first-child {
        align-items: flex-end;
        padding: 16px;
      }
    }
  }
`
