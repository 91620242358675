import { useRecoilState } from 'recoil'
import { useCentrinnoContext } from '../../context/storyContext'
import { down, normal, out, transition } from '../../helpers/animationVariants'
import { IBlock, IContext } from '../../types/types'
import Divider from '../FlexibleContent/DIvider/Divider'
import TextColumn from '../FlexibleContent/TextColumn'
import Intro from '../Intro'
import Hero from '../StoryContent/Hero/Hero'
import { AboutMain } from './styles'
import { isAnimationOnState } from '../../helpers/atoms'
import Gallery from '../FlexibleContent/Gallery'
import { Helmet } from 'react-helmet-async'

const About = () => {
  const { about } = useCentrinnoContext() as IContext
  const [isAnimationOn, setIsAnimationOn] = useRecoilState(isAnimationOnState)

  return (
    <AboutMain
      key={'about'}
      initial={isAnimationOn ? down : normal}
      animate={normal}
      exit={out}
      transition={transition}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>About the Living Archive </title>
        <meta
          name="title"
          content="About the Living Archive"
          data-react-helmet="true"
        />
        <meta
          name="title"
          content="This webspace brings together many stories collected by nine Fab City Hub teams from all over Europe. The collection has been carefully assembled by these teams who have been learning about, co-creating and applying participatory heritage-making approaches, emotion networking methodology, oral history principles and creative perspective-taking."
          data-react-helmet="true"
        />
      </Helmet>
      {about && (
        <>
          <Intro title='about' className="about" />
          <Divider />
          <TextColumn
            header={about.firstBlock.header}
            textContent={about.firstBlock?.textContent}
            className={''}
          />
          <TextColumn
            header={about.secondBlock.header}
            textContent={about.secondBlock.textContent}
            className={''}
          />
          <TextColumn
            header={about.thirdBlock.header}
            textContent={about.thirdBlock.textContent}
            className={''}
          />
          <TextColumn
            header={about.fourthBlock.header}
            textContent={about.fourthBlock.textContent}
            className={''}
          />
          <TextColumn
            header={about.fifthBlock.header}
            textContent={about.fifthBlock.textContent}
            className={''}
          />
          <Divider />
          {about.aboutGallery && (
            <Gallery
              gallery={about.aboutGallery.gallery}
              title={'About Gallery'}
              key="about-gallery"
            />
          )}
        </>
      )}
    </AboutMain>
  )
}

export default About
