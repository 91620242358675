import { IPreference, IResult } from '../types/types'
import { shuffleResults } from './shuffle'

export const setTagButtons = (tags: IPreference[]) => {
  let filterResults: IResult[] = []

  tags?.forEach((value: IPreference, index, self) => {
    if (
      index === self.findIndex((t: IPreference) => t?.title === value?.title)
    ) {
      filterResults.push({
        result: value?.title,
        isTag: true,
        isSelected: false,
      })
      index ===
        self.findIndex(
          (t: IPreference) => t?.category?.title === value?.category?.title
        ) &&
        filterResults.push({
          result: value?.category?.title as string,
          isTag: false,
          isSelected: false,
        })
    }
  })

  return shuffleResults(filterResults)
}
