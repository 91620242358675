import styled from 'styled-components'
import { accentColor, backgroundColor, secondColor } from '../../styles'

export const IntroSection = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: flex-end;
  background-color: ${accentColor};
  padding: 32px;

  h1 {
    width: 85%;
    font-size: 14vw;
    letter-spacing: -3px;
    font-weight: 800;
    line-height: 0.9;
    padding-left: 32px;
    margin-bottom: -20px;
    text-align: left;
    text-transform: uppercase;
    color: ${backgroundColor};

    div {
      overflow: hidden;
    }

    span {
      display: inline-block;
    }
  }

  .arrow-down {
    min-width: 64px;
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 2px solid ${backgroundColor};
    border-radius: 5px;
    margin-bottom: 3px;
    transition: border 0.3s ease;

    path {
      stroke: ${secondColor};
      transition: stroke 0.3s ease;
    }

    &:hover {
      border-color: ${secondColor};

      path {
        stroke: ${secondColor};
      }
    }
  }

  .intro-image {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-right: 32px;
    /* border-right: 2px solid ${backgroundColor}; */

    .right-border {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 2px;
      background-color: ${backgroundColor};
    }
  }

  p {
    width: 50%;
    margin: 32px auto 32px;
  }

  @media screen and (max-width: 1150px) {
    height: 90vh;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px;

    .intro-image {
      height: auto;
      width: 100%;
      justify-content: flex-start;
      border: none;
      padding-right: 0;
      margin-bottom: 24px;

      .right-border {
        display: none;
      }

      svg {
        width: 150px;
        height: 60px;
      }
    }

    h1 {
      padding-left: 0;
      font-size: 14vw;
    }
  }

  @media screen and (max-width: 900px) {
    h1 {
      width: 100%;
      font-size: 20vw;
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 800px) {
    height: 85vh;
    padding: 16px;
  }
`
