import { AboutMain } from '../About/styles'
import {
  down,
  hidden,
  hide,
  normal,
  out,
  show,
  transition,
} from '../../helpers/animationVariants'
import { useCentrinnoContext } from '../../context/storyContext'
import { useEffect, useState } from 'react'
import { filterOnPilots, SearchStories } from '../../helpers/searchItem'
import { IContext, IImage, IStory } from '../../types/types'
import { CSVLink } from 'react-csv'
import imageUrlBuilder from '@sanity/image-url'
import {
  Button,
  accentColor,
  backgroundColor,
  textColor,
  thirdColor,
} from '../../styles'
import IndexCard from '../IndexCard/IndexCard'
import { ResultsSection } from '../Results/styles'
import { AnimatePresence, motion } from 'framer-motion'
import CloseIcon from '../Search/CloseIcon'
import MagnifierIcon from '../Search/MagnifierIcon'
import { SearchBox, InputBox } from '../Search/styles'
import { IndexContent, IndexWrapper, SideBar } from './styles'
import { ButtonRow } from '../StoryContent/Metadata/styles'
import { slugify } from '../../helpers/slugify'
import React, { MouseEvent } from 'react'
import client from '../../sanityService'

const IndexPage = () => {
  const { stories } = useCentrinnoContext() as IContext
  const [filterValue, setFilterValue] = useState('')
  const [indexStories, setIndexStories] = useState<IStory[]>([])
  const [pilots, setPilots] = useState<string[]>([])
  const [isSelected, setIsSelected] = useState('')
  const [showClear, setShowClear] = useState(false)
  const containerElement = document.getElementById('container')
  const [showClearButton, setShowClearButton] = useState(false)

  const handleFilterOnPilot = (event: MouseEvent<HTMLElement>) => {
    const filteredStories = filterOnPilots(
      (event.target as HTMLElement).innerText,
      stories
    )
    setIndexStories([])
    setTimeout(() => {
      if (containerElement) {
        containerElement.scrollTop = 0
      }
      setIndexStories(filteredStories)
      setIsSelected((event.target as HTMLElement).innerText)
      setShowClearButton(true)
    }, 300)
  }

  const clearFilter = () => {
    setIndexStories([])
    setTimeout(() => {
      if (containerElement) {
        containerElement.scrollTop = 0
      }
      setIndexStories(stories)
      setFilterValue('')
      setShowClear(false)
      setShowClearButton(false)
      setIsSelected('')
    }, 300)
  }

  const builder = imageUrlBuilder(client)

  function urlFor(source: IImage) {
    return builder.image(source)
  }


  const getPilots = () => {
    let pilotsArray: string[] = []
    stories.forEach((element: IStory) => {
      pilotsArray.push(element.metaData?.pilotLocation)
    })
    // eslint-disable-next-line array-callback-return
    pilotsArray = pilotsArray.filter(function (item, pos) {
      if (item) return pilotsArray.indexOf(item) === pos
    })
    return pilotsArray
  }

  const csvInfo = () => {
    const csvArray: Record<string, string | number | string[]>[] = []
    indexStories.forEach((element: IStory, index: number) => {
      const storyTags = element.tags?.map((tag) => tag?.title)

      csvArray.push({
        key: index + 1,
        title: element.title || '',
        pilot: element.metaData?.pilotLocation || '',
        heroImage: urlFor(element.heroImage).format('jpg').width(1000).url(),
        tags: storyTags || '',
        url: `https://livingarchive.centrinno.eu/story/${slugify(
          element?.title
        )}`,
      })
    })
    return csvArray
  }

  useEffect(() => {
    setPilots(getPilots())
    setIndexStories(stories)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stories])

  const handleFilter = (e: string) => {
    setFilterValue(e)
    if (e) {
      setShowClear(true)
    } else {
      setShowClear(false)
    }
    if (!filterValue) return

    setIndexStories(SearchStories(e, stories))
  }

  return (
    <AboutMain
      id="container"
      key={'index'}
      initial={down}
      animate={normal}
      exit={out}
      transition={transition}
    >
      <IndexWrapper>
        <SideBar>
          <motion.div
            initial={{ height: 0 }}
            animate={{
              height: '100%',
              transition: { duration: 1, delay: 0.4, ease: 'easeInOut' },
            }}
            className="right-border"
          ></motion.div>
          <SearchBox>
            <div className="wrapper">
              <div className="inner">
                <InputBox
                  type="search"
                  id="site-search"
                  name="q"
                  placeholder="search stories"
                  autoComplete="one-time-code"
                  value={filterValue}
                  onChange={(e) => handleFilter(e.target.value)}
                />
                <AnimatePresence mode="wait">
                  {showClear ? (
                    <motion.div
                      initial={hidden}
                      animate={show}
                      exit={hide}
                      key="icon"
                      className="icon close-icon"
                    >
                      <CloseIcon handleClear={clearFilter} />
                    </motion.div>
                  ) : (
                    <motion.div
                      initial={hidden}
                      animate={show}
                      exit={hide}
                      key="icon-square"
                      className="icon"
                    >
                      <MagnifierIcon />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </SearchBox>
          <ButtonRow>
            <Button
              className="small clear flex csv-button"
              buttonColor={backgroundColor}
              backgroundColor={accentColor}
              buttonTextColor={backgroundColor}
            >
              <CSVLink
                filename={
                  isSelected !== ''
                    ? `${isSelected}-stories-centrinno.csv`
                    : 'all-stories-centrinno.csv'
                }
                data={csvInfo()}
              >
                <p>export csv file</p>
              </CSVLink>
            </Button>
            <AnimatePresence>
              {showClearButton && (
                <motion.div initial={hidden} animate={show} exit={hide}>
                  <Button
                    className="small clear no-hover flex"
                    buttonColor={thirdColor}
                    backgroundColor={thirdColor}
                    buttonTextColor={textColor}
                    onClick={clearFilter}
                  >
                    <p>clear selection</p>
                  </Button>
                </motion.div>
              )}
            </AnimatePresence>
          </ButtonRow>
          <ResultsSection>
            {pilots.length > 0 &&
              pilots.map((pilot) => (
                <div className="filter-element" key={pilot}>
                  <Button
                    buttonColor="white"
                    backgroundColor="white"
                    buttonTextColor={textColor}
                    onClick={handleFilterOnPilot}
                    className={
                      isSelected === pilot.toLowerCase()
                        ? 'selected small clear no-hover'
                        : 'small clear no-hover'
                    }
                  >
                    <p>{pilot}</p>
                  </Button>
                </div>
              ))}
          </ResultsSection>
        </SideBar>
        <IndexContent>
          <AnimatePresence>
            {indexStories.length > 0 &&
              indexStories.map((story: IStory) => {
                return (
                  <IndexCard
                    key={story?.title}
                    image={story?.heroImage}
                    title={story?.title}
                    tags={story?.tags}
                    summary={story?.summaryText}
                  />
                )
              })}
          </AnimatePresence>
        </IndexContent>
      </IndexWrapper>
    </AboutMain>
  )
}

export default IndexPage
