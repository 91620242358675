import styled from 'styled-components'
import { Button, backgroundColor, textColor } from '../../styles'
import { motion } from 'framer-motion'
import { Card } from '../StoryCard/styles'
import { ButtonRow } from '../StoryContent/Metadata/styles'

export const SliderWrapper = styled(motion.section)`
  position: relative;
  width: 100%;
  height: 90vh;
  z-index: 1;

  @media screen and (max-height: 700px) {
    height: 120vh;
  }

  @media screen and (min-height: 900px) {
    height: 80vh;
  }

  @media screen and (min-height: 1000px) and (max-width: 1050px) {
    height: 65vh;
  }

  @media screen and (min-height: 1000px) and (min-width: 1900px) {
    height: 65vh;
  }

  .empty {
    width: 50%;
    padding: 24px 32px;
  }

  .story-loader {
    position: absolute;
    top: 32px;
    left: 32px;
    width: 100%;
    height: calc(100% - 32px);
    z-index: 99;
    background-color: ${backgroundColor};
  }

  ${ButtonRow} {
    position: absolute;
    z-index: 100;
    right: 0;
    gap: 10px;
    top: 0;
    padding-right: 32px;

    ${Button} {
      padding: 16px;

      &:first-child {
        transform: rotate(90deg);

        svg {
          margin-bottom: -4px;
        }
      }

      &:last-child {
        transform: rotate(-90deg);

        svg {
          margin-top: 8px;
        }
      }
    }

    @media only screen and (max-width: 1150px) {
      padding-right: 24px;
    }

    @media only screen and (max-width: 900px) {
      display: none;
    }
  }

  .container {
    position: relative;
    height: 100%;
    padding: 120px 0 0px 0;
    display: grid;
    place-items: start;
    overflow-y: visible;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    flex-wrap: no-wrap;
    &::-webkit-scrollbar {
      display: none;
    }

    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 101;
      background-color: ${textColor};
    }
    ${Card} {
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 900px) {
    .container {
      padding: 120px 0 64px 0;
    }

    .story-loader {
      top: 24px;
      left: 24px;
    }
  }

  @media only screen and (max-width: 700px) {
    height: 75vh;

    .empty {
      width: 100%;
      padding: 16px 24px;
    }

    .container {
      padding: 64px 0 64px 0;
    }

    .story-loader {
      top: 16px;
      left: 16px;
    }
  }
`
