import client from '../sanityService'
import imageUrlBuilder from '@sanity/image-url'
import { IImage } from '../types/types'
import { useRef, useState } from 'react'
import { backgroundColor, textColor } from '../styles'
import { AnimatePresence, motion } from 'framer-motion'

interface ImgHandlerProps {
  imgSrc: IImage
  altText: string
  width: number
  noPlaceholder?: boolean
}
const ImgHandler = ({
  imgSrc,
  altText,
  width,
  noPlaceholder,
}: ImgHandlerProps) => {
  const builder = imageUrlBuilder(client)
  const [isLoaded, setIsLoaded] = useState(false)
  const containerRef = useRef(null)

  const handleLoad = () => {
    setIsLoaded(true)
  }

  function urlFor(source: IImage) {
    return builder.image(source)
  }

  return (
    <div ref={containerRef} className="image-container">
      <picture>
        <source srcSet={urlFor(imgSrc).format('webp').width(width).url()} />
        <source srcSet={urlFor(imgSrc).format('jpg').width(width).url()} />
        <AnimatePresence>
          {!isLoaded && !noPlaceholder && (
            <motion.div
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { duration: 1.2 } }}
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: textColor,
              }}
              viewport={{ once: true, amount: 0.6 }}
            />
          )}
        </AnimatePresence>
        <img
          src={urlFor(imgSrc).width(width).url()}
          alt={altText}
          onLoad={handleLoad}
        />
      </picture>
    </div>
  )
}

export default ImgHandler
