import styled from 'styled-components'
import { accentColor, backgroundColor, secondColor } from '../../styles'

export const FooterWrapper = styled.div`
  position: relative;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  padding: 32px;
  background-color: ${accentColor};
  color: ${backgroundColor};
  font-size: 12px;

  p {
    font-size: 12px;
  }

  .connect {
    margin-top: 1rem;
  }

  a {
    color: ${backgroundColor};
    font-size: 12px;
  }

  .half {
    &:first-child {
      svg {
        width: 700px;
      }
    }

    &:nth-child(2) {
      width: 100%;
      .top {
        display: flex;
        align-items: flex-start;

        .logos {
          width: auto;
          display: flex;
          align-items: flex-start;
          gap: 16px;
          img {
            &:first-child {
              width: 120px;
            }
            &:last-child {
              width: 78px;
            }
          }
        }

        p {
          width: auto;
          max-width: 400px;
          font-size: 12px;
          padding-left: 16px;
          color: ${backgroundColor};
        }
      }

      img.rein {
        width: 220px;
        margin-top: 0px;
      }

      ul {
        padding: 0;
        li {
          position: relative;
          padding-left: 16px;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;

          a {
            text-transform: none;
            margin-left: 6px;
          }

          &::before {
            content: '';
            position: absolute;
            top: 5px;
            left: 0;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: ${secondColor};
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1360px) {
    .half {
      &:nth-child(2) {
        .top {
          .logos {
            min-width: 78px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1150px) {
    flex-wrap: wrap;
    flex-direction: column;
    gap: 24px;
    padding: 24px;

    align-items: flex-end;

    .half {
      width: 100%;

      &:first-child {
        svg {
          width: 100%;
        }
      }

      &:nth-child(2) {
        .top {
          .logos {
            flex-wrap: nowrap;
          }

          p {
            width: auto;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 800px) {
    gap: 16px;
    padding: 16px;
    flex-direction: column;
  }

  @media only screen and (max-width: 600px) {
    .half {
      &:nth-child(2) {
        .top {
          .logos {
            flex-wrap: nowrap;
            min-width: none;
            width: auto;
            /* width: 50%; */

            /* img {
              &:first-child {
                width: 60%;
              }
              &:last-child {
                width: 40%;
              }
            } */
          }

          p {
            width: auto;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    font-size: 10px;

    a {
      font-size: 10px;
    }

    .half {
      &:nth-child(2) {
        .top {
          .logos {
            flex-wrap: wrap;
            width: auto;

            img {
              &:first-child {
                width: 100%;
              }
              &:last-child {
                width: 100%;
              }
            }
          }

          p {
            width: 100%;
            font-size: 10px;
          }
        }
      }
    }
  }
`
