import { useEffect, useRef, useState } from 'react'
import { width } from '../../../helpers/windowHelpers'
import { IImage } from '../../../types/types'
import ImgHandler from '../../ImgHandler'
import { GalleryImage, GallerySection } from './styles'
import { motion } from 'framer-motion'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
import { Button, fadedWhite, secondColor } from '../../../styles'
import { ButtonRow } from '../../StoryContent/Metadata/styles'
import { useLocation } from 'react-router-dom'

interface GalleryProps {
  gallery: IImage[]
  title: string
}

const Gallery = ({ gallery, title }: GalleryProps) => {
  const [currentSlide, setCurrentSlide] = useState<number | null>(0)
  const location = useLocation()

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    loop: false,
    mode: 'free',
    breakpoints: {
      '(min-width: 500px)': {
        slides: { perView: 'auto', spacing: 4 },
      },
      '(min-width: 900px)': {
        slides: { perView: 'auto', spacing: 6 },
      },
    },
    slides: {
      perView: 'auto',
      spacing: 8,
    },
  })

  useEffect(() => {
    instanceRef?.current?.destroy()

    setTimeout(() => {
      instanceRef?.current?.update({
        loop: false,
        mode: 'free',
        slides: {
          perView: 'auto',
          spacing: 8,
        },
      })
    }, 500)
  }, [title])

  return (
    <GallerySection>
      <motion.div ref={sliderRef} className="wrapper">
        {gallery &&
          gallery.map((image: IImage, index: number) => (
            <GalleryImage className="keen-slider__slide" key={index}>
              <ImgHandler
                imgSrc={image}
                altText={`${title}-galleryimage-${index}`}
                width={1000}
              />
            </GalleryImage>
          ))}
      </motion.div>
      <ButtonRow>
        <Button
          backgroundColor={fadedWhite}
          buttonColor={secondColor}
          className="arrow"
          onClick={() => instanceRef.current?.prev()}
        >
          <svg
            width="23"
            height="15"
            viewBox="0 0 23 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <motion.path
              d="M21.503 1.9772L11.492 11.9882L1.48095 1.9772"
              stroke={secondColor}
              strokeWidth="2"
            />
          </svg>
        </Button>
        <Button
          backgroundColor={fadedWhite}
          buttonColor={secondColor}
          className="arrow"
          onClick={() => instanceRef.current?.next()}
        >
          <svg
            width="23"
            height="15"
            viewBox="0 0 23 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <motion.path
              d="M21.503 1.9772L11.492 11.9882L1.48095 1.9772"
              stroke={secondColor}
              strokeWidth="2"
            />
          </svg>
        </Button>
      </ButtonRow>
    </GallerySection>
  )
}

export default Gallery
