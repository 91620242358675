import { motion } from 'framer-motion'
import styled from 'styled-components'
import { textColor } from '../../../styles'

export const ProgressSection = styled(motion.div)`
  position: sticky;
  top: 0;
  height: 50vh;
  padding: 32px;

  @media screen and (max-width: 1150px) {
    padding: 24px;
  }

  @media screen and (max-width: 800px) {
    height: 50vh;
    padding: 16px;
  }
`

export const InnerProgress = styled(motion.div)`
  position: sticky;
  top: 180px;
  width: calc(50% - 32px);

  h2 {
    font-size: 40px;
    margin-bottom: 24px;
    font-weight: 800;
    text-transform: uppercase;
  }

  p {
    font-size: 12px;
  }

  .progress-container {
    position: relative;
    width: 50%;
    height: 15px;
    border: 1px solid ${textColor};
    border-radius: 4px;
    overflow: hidden;
    .progress-bar {
      height: 100%;
      width: 100%;
      transform-origin: left;
      position: absolute;
      top: 0;
      left: 0;
      background-color: ${textColor};
    }
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    top: 80px;

    h2 {
      font-size: 20px;
      margin-bottom: 24px;
    }

    .progress-container {
      width: 100%;
      height: 12px;

      .progress-bar {
        transition: none;
      }
    }
  }
`
