import { FooterWrapper } from './styles'
import euImg from '../../images/eu.svg'
import reinwardtLogo from '../../images/AHK-RWA-logo_ENG-RGB-diap-grijs.png'
import { Button, secondColor, textColor } from '../../styles'

const Footer = () => {
  return (
    <FooterWrapper>
      <div className="half">
        <svg
          width="100%"
          viewBox="0 0 802 289"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.4"
            d="M0.166448 288.959L0.166016 0L235.673 237.776V0L471.18 237.776V0H801.66V288.959H0.166448Z"
            fill="#29A6E4"
          />
        </svg>
      </div>
      <div className="half">
        <div className="top">
          <div className="logos">
            <img src={euImg} alt="European Union Logo" />
          </div>
          <p>
            This project has received funding from the European Union's Horizon
            2020 Research and Innovation programme under grant agreement n.
            869595
          </p>
        </div>
        <div className="list">
          <img
            src={reinwardtLogo}
            alt="Reinwardt Academy Logo"
            className="rein"
          />

          <ul>
            <li>
              Concept by
              <a
                href="https://www.reinwardt.ahk.nl"
                rel="noreferrer"
                target="_blank"
              >
                Reinwardt Academie
              </a>
            </li>
            <li>
              Design and Development by
              <a
                href="https://aplusplus.studio"
                rel="noreferrer"
                target="_blank"
              >
                A++ Studio
              </a>
            </li>
          </ul>
        </div>
        <div className="connect">
          <p>connect with CENTRINNO</p>
          <a href="https://centrinno.eu" target="_blank" rel="noreferrer">
            <Button
              backgroundColor={secondColor}
              buttonColor={secondColor}
              color={textColor}
            >
              connect
            </Button>
          </a>
        </div>
      </div>
    </FooterWrapper>
  )
}

export default Footer
