import styled from 'styled-components'
import {
  Button,
  accentColor,
  backgroundColor,
  secondColor,
  textColor,
} from '../../styles'

export const ResultsWrapper = styled.section`
  width: 100%;
  position: relative;
  height: 232px;
  overflow: hidden;
  padding: 0 32px 24px;
  margin-bottom: 12px;

  @media only screen and (max-width: 1150px) {
    padding: 0 24px 24px;
  }

  @media only screen and (max-width: 980px) {
    height: 192px;
    padding: 0 24px;
    margin-bottom: 8px;
  }

  @media screen and (max-width: 800px) {
      padding: 0 16px;
  }
`

export const SelectedRow = styled.div`
  width: 100%;
  padding: 0 32px;
  .wrapper {
    width: 100%;
    padding: 16px 0 0;
    border-top: 1px solid ${textColor};
    display: flex;

    .inner {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      gap: 4px;
      flex-wrap: wrap;
    }

    .clear {
      width: 80px;
    }
  }

  @media only screen and (max-width: 1150px) {
    padding: 0 24px;

    .wrapper {
      width: 100%;
    }
  }

  @media screen and (max-width: 800px) {
    padding: 0 16px;
  }
`

export const ResultsSection = styled.div`
  width: 100%;
  height: auto;
  max-height: 232px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: scroll;
  gap: 6px;
  mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 4px, black 4px);
  mask-size: 100% 20000px;
  mask-position: left bottom;
  -webkit-mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 4px, black 4px);
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  transition: mask-position 0.3s, -webkit-mask-position 0.3s;
  scrollbar-color: ${backgroundColor} ${backgroundColor};
  scrollbar-width: none;
  &:hover {
    mask-position: left top;
    -webkit-mask-position: left top;
  }
  &::-webkit-scrollbar {
    width: 18px;
    padding: 0 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-left: 16px solid ${backgroundColor};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${accentColor}; 
    border-left: 16px solid ${backgroundColor};
    border-top: 2px solid ${backgroundColor};
    border-bottom: 2px solid ${backgroundColor};
  }

  .filter-element {
    ${Button} {
      padding: 8px 16px;
      border: 0;
      p {
        font-size: 12px;
      }
      &:hover {
        background-color: ${textColor};
        p {
          color: ${backgroundColor} !important;
        }
      }
    }
  }

  @media screen and (max-width: 1150px) {
    width: 100%;
  }

  @media screen and (max-width: 980px) {
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 192px;
    flex-wrap: wrap;
    gap: 4px;
    mask-image: none;
    mask-size: 0 0px;
    mask-position: left bottom;
    -webkit-mask-image: none;
    -webkit-mask-size: 0 0px;
    -webkit-mask-position: left bottom;
    padding: 8px 0 8px;

    &::-webkit-scrollbar {
      width: 100%;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-left: 0;
      height: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${accentColor};
      height: 4px;
      border-left: 0;
      border-top: 2px solid ${backgroundColor};
    }

    .filter-element {
      flex: none;
      margin-bottom: 0;

      button {
        padding: 8px 14px;
        border: 0 !important;

        p {
          font-size: 12px;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    padding: 8px 0;
  }
`
