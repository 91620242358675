import { Story } from './components/Story'
import { Route, Routes, useLocation } from 'react-router-dom'
import { CentrinnoProvider } from './context/storyContext'
import { RecoilRoot } from 'recoil'
import { ThemeProvider } from 'styled-components'
import React, { useEffect, useState } from 'react'
import { GlobalStyles } from './styles'
import NoMatch from './components/404/NoMatch'
import Home from './components/Home/Home'
import Navigation from './components/Navigation'
import About from './components/About'
import { AnimatePresence } from 'framer-motion'
import { searchButtons } from './helpers/searchItem'
import HomeContent from './components/HomeContent/HomeContent'
import { IResult } from './types/types'
// import { Helmet } from 'react-helmet'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import IndexPage from './components/IndexPage'
import { ContributePage } from './components/ContributePage'

function App() {
  const location = useLocation()
  const [filteredButtons] = useState<IResult[]>([])
  const [inputValue] = useState('')
  const [, setRefinedButtons] = useState<IResult[]>([])

  useEffect(() => {
    setRefinedButtons(searchButtons(inputValue, filteredButtons))
  }, [inputValue, filteredButtons])

  useEffect(() => {
    if (location.pathname.includes('story')) {
      document.body.classList.add('blocked')
    } else if (location.pathname.includes('index')) {
      document.body.classList.add('blocked')
    } else if (location.pathname.includes('about')) {
      document.body.classList.add('blocked')
    } else {
      document.body.classList.remove('blocked')
    }
  }, [location.pathname])

  return (
    <React.Fragment>
      <HelmetProvider>
        <RecoilRoot>
          <Helmet>
            <meta charSet="utf-8" />
            <title>The Living Archive - Centrinno </title>
            <meta
              name="title"
              content="The Living Archive - Centrinno"
              data-react-helmet="true"
            />
            <meta
              name="title"
              content="This webspace brings together many stories collected by nine Fab City Hub teams from all over Europe. The collection has been carefully assembled by these teams who have been learning about, co-creating and applying participatory heritage-making approaches, emotion networking methodology, oral history principles and creative perspective-taking."
              data-react-helmet="true"
            />
          </Helmet>
          <ThemeProvider theme={{ variant: 'default', mode: 'light' }}>
            <GlobalStyles />
            <CentrinnoProvider>
              <Navigation />
              <HomeContent />
              <AnimatePresence initial={false}>
                <Routes location={location} key={location.pathname}>
                  <Route index element={<Home />}></Route>
                  <Route path="/*" element={<NoMatch />}></Route>
                  <Route
                    key="about-page"
                    path="about"
                    element={<About />}
                  ></Route>
                  <Route
                    key="index"
                    path="index"
                    element={<IndexPage />}
                  ></Route>
                  <Route
                    key="contribute"
                    path="contribute"
                    element={<ContributePage />}
                  ></Route>

                  <Route
                    key={location.key}
                    path="/story/:slug"
                    element={<Story />}
                  ></Route>
                </Routes>
              </AnimatePresence>
            </CentrinnoProvider>
          </ThemeProvider>
        </RecoilRoot>
      </HelmetProvider>
    </React.Fragment>
  )
}

export default App
