const MagnifierIcon = () => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10.4375"
        cy="6.48389"
        r="5.1875"
        stroke="#1E1E1E"
        strokeWidth="2"
      />
      <path
        d="M6.85664 10.8923L1.64331 16.5451"
        stroke="#1E1E1E"
        strokeWidth="2"
      />
    </svg>
  )
}

export default MagnifierIcon
