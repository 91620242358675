import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { hidden, hide, show } from '../../../helpers/animationVariants'
import { InnerProgress, ProgressSection } from './styles'

interface ProgressProps {
  progress: number
}

const ProgressBar = ({ progress }: ProgressProps) => {
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    if (progress && progress >= 0.99) {
      let timer2 = setTimeout(() => {
        setIsVisible(false)
      }, 550)
      return () => {
        clearTimeout(timer2)
      }
    }
  }, [progress])

  return (
    <>
      <AnimatePresence>
        {isVisible && (
          <ProgressSection
            id="progress-section"
            key="progress"
            initial={hidden}
            animate={show}
            exit={hide}
          >
            <InnerProgress initial={hidden} animate={show} exit={hide}>
              <motion.h2 initial={hidden} animate={show} exit={hide}>
                Next Story
              </motion.h2>
              <motion.p initial={hidden} animate={show} exit={hide}>
                Scroll down
              </motion.p>
              <motion.div
                className="progress-container"
                initial={hidden}
                animate={show}
                exit={hide}
              >
                <motion.div
                  className="progress-bar"
                  style={{
                    transform: `scale(${progress}, 1)`,
                  }}
                  initial={hidden}
                  animate={show}
                  exit={hide}
                ></motion.div>
              </motion.div>
            </InnerProgress>
          </ProgressSection>
        )}
      </AnimatePresence>
    </>
  )
}

export default ProgressBar
