import styled from 'styled-components'
import { textColor } from '../../../styles'

export const DividerLine = styled.div`
  padding: 32px 32px 0 32px;
  .inner {
    height: 1px;
    width: 100%;
    background-color: ${textColor};
  }

  @media screen and (max-width: 1150px) {
    padding: 24px 24px 0 24px;
  }

  @media screen and (max-width: 800px) {
    padding: 16px 16px 0 16px;
  }
`
