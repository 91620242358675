import styled from 'styled-components'
import {
  Button,
  backgroundColor,
  fadedWhite,
  secondBgColor,
} from '../../styles'

export const NavigationBox = styled.nav`
  position: fixed;
  top: 32px;
  left: 32px;
  z-index: 120;
  padding: 8px 8px;
  background-color: ${secondBgColor};
  opacity: 1;
  border-radius: 5px;
  box-shadow: 2px 2px 4px rgba(30, 30, 30, 0.2);
  .container {
    display: flex;
    justify-content: flex-start;
  }

  ${Button} {
    background-color: ${secondBgColor};
    text-transform: lowercase;
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;
    align-items: center;
    font-weight: 800;
    &:hover {
      background-color: ${secondBgColor} !important;
    }

    &.mr-0 {
      margin-right: 0;
    }

    &.mobile-menu {
      display: none;
    }

    &.button-wrapper {
      padding: 0;
    }
    border: none;

    p {
      font-weight: 800;
    }

    svg {
      width: 16px;
      height: 10px;
      margin-right: 3px;

      &:first-child {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: 1150px) {
    top: 24px;
    left: 24px;

    ${Button} {
      margin-right: 10px;
      &.button-wrapper {
        padding: 0;
      }
      svg {
        width: 12px;
        height: 8px;
      }
    }
  }

  @media screen and (max-width: 800px) {
    top: 16px;
    left: 16px;
    padding: 0;
    background-color: transparent;
    box-shadow: none;

    .container {
      flex-direction: column;
      gap: 8px;
    }

    ${Button} {
      &.mobile-menu {
        position: relative;
        z-index: 121;
        display: inline-block;
        background-color: ${secondBgColor};
      }

      background-color: ${secondBgColor};
      box-shadow: 2px 2px 4px rgba(30, 30, 30, 0.2);
      margin-right: 8px;
    }
  }
`
