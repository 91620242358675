import styled from 'styled-components'

export const ContributeWrapper = styled.div`
  padding: 0 32px;
  margin-top: 56px;
  .text {
    position: relative;
    width: 100%;
    padding: 10rem 0;
    display: flex;

    .text-content {
        a {
            text-decoration: underline;
        }
    }

    div {
      width: 50%;
      &:first-child {
        padding-right: 6rem;
      }

      p {
        margin-bottom: 2rem;
      }

      button {
        border: none;
      }
    }

    h3 {
      font-size: 4.4vw;
      line-height: 0.95;
      font-weight: 800;
      text-transform: uppercase;
    }
  }

  @media screen and (max-width: 1150px) {
    padding: 0 24px;

    .text {
      width: 100%;
    }
  }

  @media screen and (max-width: 800px) {
    padding: 0 16px;

    .text {
      flex-direction: column;
      gap: 1.5rem;
      div {
        width: 100%;

        &:first-child {
          padding-right: 0;
        }
      }

      h3 {
        font-size: 15vw;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .text {
      h3 {
        font-size: 20vw;
      }
    }
  }
`
