import ImgHandler from '../../ImgHandler'
import { IImage } from '../../../types/types'
import { TwoImagesSection } from './styles'

interface TwoImagesProps {
  imgOne: IImage
  imgTwo: IImage
  className: string
  title: string
}

function TwoImages({ imgOne, imgTwo, className, title }: TwoImagesProps) {
  const min = Math.ceil(1)
  const max = Math.floor(50)
  return (
    <TwoImagesSection className={className}>
      <ImgHandler
        imgSrc={imgOne}
        altText={`${title}-${Math.floor(Math.random() * (max - min) + min)}`}
        width={2000}
      />
      <ImgHandler
        imgSrc={imgTwo}
        altText={`${title}-${Math.floor(Math.random() * (max - min) + min)}`}
        width={2000}
      />
    </TwoImagesSection>
  )
}

export default TwoImages
