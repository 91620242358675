import { atom } from 'recoil'

export const isAnimationOnState = atom({
  key: 'isAnimationOn',
  default: true,
})

export const isExitDelayedState = atom({
  key: 'isExitDelayed',
  default: false,
})
