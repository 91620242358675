import { Link } from 'react-router-dom'
import { IImage } from '../../../types/types'
import ImgHandler from '../../ImgHandler'
import { HeroGroup } from './styles'
import { useRecoilState } from 'recoil'
import { isAnimationOnState } from '../../../helpers/atoms'
import React from 'react'
import { motion } from 'framer-motion'

interface NextHeroProps {
  title?: string
  image?: IImage
  link?: string
  ref?: React.Ref<HTMLDivElement>
}

const NextHero = React.forwardRef<HTMLDivElement, NextHeroProps>(
  ({ image, title, link }, ref) => {
    const [, setIsAnimationOn] = useRecoilState(isAnimationOnState)

    const clickHandler = () => {
      setIsAnimationOn(false)
    }

    return (
      <HeroGroup id="next-story-hero" className="next-hero">
        <motion.div className="half" initial={{ opacity: 1 }}>
          <h1>{title && title}</h1>
        </motion.div>
        <motion.div
          className="half"
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
        >
          {image && title && (
            <ImgHandler
              imgSrc={image}
              altText={`${title.toLowerCase()}-hero-image`}
              width={2000}
            />
          )}
        </motion.div>
        {link && <Link to={link} onClick={clickHandler}></Link>}
      </HeroGroup>
    )
  }
)

export default NextHero
