import styled from 'styled-components'
import { backgroundColor } from '../../styles'
import { motion } from 'framer-motion'

export const StoryPage = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 103;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  background-color: ${backgroundColor};
`

export const StoryBody = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  #next-story-hero {
    margin-top: 50px;
  }
`
