import styled from 'styled-components'
import { Button, backgroundColor, secondColor } from '../../../styles'

export const RangeContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  padding: 24px;
  border: 1px solid ${secondColor};
  border-radius: 10px;
  margin: 32px;

  ${Button} {
    min-width: 64px;
    padding: 0;
    &:hover {
      opacity: 0.7;
      background-color: ${backgroundColor};
    }
  }

  @media only screen and (max-width: 1150px) {
    width: calc(100% - 48px);
    margin: 24px;
  }

  @media only screen and (max-width: 800px) {
    width: calc(100% - 32px);
    margin: 16px;
    padding: 12px;

    ${Button} {
      min-width: 38px;
      padding: 0;
      font-size: 14px;
    }
  }
`
