import { motion } from 'framer-motion'
import { down, normal, out, transition } from '../../helpers/animationVariants'
import { AboutMain } from '../About/styles'
import { IndexWrapper, SideBar } from '../IndexPage/styles'
import { SearchBox } from '../Search/styles'
import { Helmet } from 'react-helmet-async'
import { useCentrinnoContext } from '../../context/storyContext'
import { IContext } from '../../types/types'
import { useEffect } from 'react'
import type { TypedObject } from '@portabletext/types'
import { ContributeWrapper } from './styles'
import Form from '../Form/Form'
import Footer from '../Footer/Footer'
import { PortableText } from '@portabletext/react'

export const ContributePage = () => {
  const { contribute } = useCentrinnoContext() as IContext

  const textContent = contribute?.textBlock as TypedObject

  useEffect(() => {
    console.log(contribute)
  }, [])

  return (
    <AboutMain
      id="contribute"
      key={'contribute'}
      initial={down}
      animate={normal}
      exit={out}
      transition={transition}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contribute to The Living Archive </title>
        <meta
          name="title"
          content="Contribute to The Living Archive"
          data-react-helmet="true"
        />
        <meta
          name="title"
          content="Do you have a story that you think belongs in The Living Archive? Use this page to submit your details and some informatiom about your story. Our team will review it and add it to the archive if there is a fit."
          data-react-helmet="true"
        />
      </Helmet>
      <ContributeWrapper>
        <div className="text">
          <div>
            <h3>{contribute?.title}</h3>
          </div>
          <div className='text-content'>
            <PortableText value={textContent} />
          </div>
        </div>

        <Form />
      </ContributeWrapper>
    </AboutMain>
  )
}
