import styled from 'styled-components'
import { Button } from '../../styles'

export const StyledForm = styled.form`
  padding-bottom: 8rem;

  p {
    font-weight: 600;
    font-size: 1.5rem;
  }

  @media screen and (max-width: 800px) {
    ${Button} {
      width: 100%;
    }
  }
`

export const FormField = styled.div`
  margin-bottom: 2rem;
  max-width: 800px;

  &.flex {
    display: flex;
    gap: 2rem;

    div {
      width: 100%;
    }
  }

  label {
    display: block;
    padding-bottom: 0.75rem;
  }

  input,
  textarea {
    width: 100%;
    border: none;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    padding: 8px 16px;
    border-radius: 4px;
  }

  textarea {
    min-height: 150px;
  }

  @media screen and (max-width: 800px) {
    margin-bottom: 1.5rem;
    &.flex {
      flex-direction: column;
      gap: 1.5rem;
    }
  }
`
